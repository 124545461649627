import { UTCDateMini } from "@date-fns/utc";
import {
  startOfYear,
  endOfYear,
  eachDayOfInterval,
  eachHourOfInterval,
  startOfDay,
  endOfDay,
  differenceInDays,
  addDays,
  subDays,
} from "date-fns";

import { useMetric } from "@/hooks";
import { getRowsFromMetric } from "@/hooks/charts";
import { metric_name } from "@/client";
import { useQuery } from "@tanstack/react-query";
import { getMetricOptions } from "@/client/@tanstack/react-query.gen";
import {
  dateHandler,
  stringArrayHandler,
  useQueryParam,
  useQueryState,
} from "@/hooks/useQueryState";
import { useParams } from "react-router-dom";
import {
  addInterval,
  generateDateRange,
  HistogramData,
  transformHistogramData,
} from "./helper";

function useMetricDates() {
  const now = new Date();
  const tomorrow = addDays(now, 1);
  const thirtyDaysAgo = subDays(now, 30);
  const since = useQueryParam("since", thirtyDaysAgo, dateHandler);
  const until = useQueryParam("until", tomorrow, dateHandler);
  return { since, until };
}

export function useMetricV2(
  metricName: metric_name,
  interval: string,
  since: Date,
  until: Date
) {
  const { projectId } = useParams();
  const [aliases] = useQueryState("alias", [], stringArrayHandler);

  const query = useQuery({
    ...getMetricOptions({
      query: {
        project: [Number(projectId)],
        alias: aliases,
        interval,
        metric_name: metricName,
        since,
        until,
      },
    }),
  });
  return query;
}

export function useHistogram(
  metricName: metric_name,
  groupingInterval: string
) {
  const { since: startDate, until: endDate } = useMetricDates();
  // NOTE(memben): Each date is the start date of a date_bin in [startDate, endDate)]
  const binStartDates = generateDateRange(startDate, endDate, groupingInterval);

  // NOTE(memben): make sure the date_bin will always cover the full range
  const binRangeStart = new Date(binStartDates[0]);
  const binRangeEnd = addInterval(
    new Date(binStartDates[binStartDates.length - 1]),
    groupingInterval
  );

  const {
    data: metricResult,
    isPending,
    error,
  } = useMetricV2(metricName, groupingInterval, binRangeStart, binRangeEnd);

  // Transform the data using the utility function
  const { data, keys } = metricResult?.data
    ? transformHistogramData(
        metricResult.data as HistogramData[],
        binStartDates
      )
    : { data: [], keys: [] };

  return {
    data,
    keys,
    isPending,
    error,
  };
}

// --------------------------

// Helper function to merge series with dates that have no data
const mergeDateSeries = (
  series1: { timestamp: UTCDateMini; [key: string]: any }[],
  series2: { timestamp: UTCDateMini; [key: string]: any }[]
) => {
  const result: { timestamp: UTCDateMini; [key: string]: any }[] = [];
  let i = 0,
    j = 0;

  while (i < series1.length || j < series2.length) {
    if (
      j >= series2.length ||
      (i < series1.length &&
        series1[i].timestamp.getTime() < series2[j].timestamp.getTime())
    ) {
      result.push(series1[i++]);
    } else if (
      i >= series1.length ||
      series1[i].timestamp.getTime() > series2[j].timestamp.getTime()
    ) {
      result.push(series2[j++]);
    } else {
      result.push({ ...series1[i], ...series2[j] });
      i++;
      j++;
    }
  }

  return result;
};

// Helper to format data for heatmap
const formatForHeatmap = (
  data: { timestamp: UTCDateMini; count: number }[],
  type: "daily" | "hourly"
) => {
  return data.map((item) => ({
    timestamp: item.timestamp,
    value: item.count || 0,
    day:
      type === "daily"
        ? item.timestamp.getUTCDate()
        : item.timestamp.getUTCHours(),
    month: item.timestamp.toLocaleString("en-US", {
      month: "short",
      timeZone: "UTC",
    }),
    weekday: item.timestamp.toLocaleString("en-US", {
      weekday: "short",
      timeZone: "UTC",
    }),
  }));
};

export function useMessageCountByDay(year: number) {
  const query = {
    metricName: "MessageCount",
    interval: "1d",
    since: startOfYear(new UTCDateMini(year, 0, 1)).toISOString(),
    until: endOfYear(new UTCDateMini(year, 0, 1)).toISOString(),
  };

  const { metric, status } = useMetric(query);

  if (status !== "succeeded") {
    return { data: [], average: 0, total: 0, status };
  }

  // Create series with all days of the year
  const dateSeries = eachDayOfInterval({
    start: new UTCDateMini(query.since),
    end: new UTCDateMini(query.until),
  }).map((d) => ({ timestamp: new UTCDateMini(d), count: 0 }));

  // Process metric data
  const rows = getRowsFromMetric(metric);
  const metricData = rows.map(
    ({
      StartTimestamp,
      MessageCount,
    }: {
      StartTimestamp: string;
      MessageCount: number;
    }) => ({
      timestamp: new UTCDateMini(StartTimestamp),
      count: MessageCount,
    })
  );

  // Merge series to include days with no data
  const mergedSeries = mergeDateSeries(dateSeries, metricData);

  // Calculate statistics
  const total = mergedSeries.reduce((sum, day) => sum + (day.count || 0), 0);
  const average = total / mergedSeries.length;

  // Format data for heatmap
  const data = formatForHeatmap(mergedSeries as any, "daily");

  return {
    data,
    average,
    total,
    status,
  };
}

export function useMessageCountByHour(since: Date, until: Date) {
  const query = {
    metricName: "MessageCount",
    interval: "1h",
    since: startOfDay(since).toISOString(),
    until: endOfDay(until).toISOString(),
  };

  const { metric, status } = useMetric(query);

  if (status !== "succeeded") {
    return { data: [], average: 0, total: 0, status };
  }

  // Create series with all hours in the interval
  const dateSeries = eachHourOfInterval({
    start: new UTCDateMini(query.since),
    end: new UTCDateMini(query.until),
  }).map((d) => ({ timestamp: new UTCDateMini(d), count: 0 }));

  // Process metric data
  const rows = getRowsFromMetric(metric);
  const metricData = rows.map(
    ({
      StartTimestamp,
      MessageCount,
    }: {
      StartTimestamp: string;
      MessageCount: number;
    }) => ({
      timestamp: new UTCDateMini(StartTimestamp),
      count: MessageCount,
    })
  );

  // Merge series to include hours with no data
  const mergedSeries = mergeDateSeries(dateSeries, metricData);

  // Calculate statistics
  const days = differenceInDays(until, since) + 1;
  const total = mergedSeries.reduce((sum, hour) => sum + (hour.count || 0), 0);
  const average = total / (days * 24); // average per hour

  // Format data for heatmap
  const data = formatForHeatmap(mergedSeries as any, "hourly");

  return {
    data,
    average,
    total,
    status,
  };
}
