import { LanguageInfo } from "@/ietf-bcp-47";
import {
  Dialog,
  DialogContent,
  DialogFooter,
  DialogHeader,
  DialogTitle,
} from "../ui/dialog";
import {
  Command,
  CommandEmpty,
  CommandGroup,
  CommandInput,
  CommandItem,
  CommandList,
} from "../ui/command";
import { Button } from "../ui/button";
import { LanguageDisplay } from "./LanguageDisplay";

interface LanguageDialogProps {
  title: string;
  open: boolean;
  onOpenChange: (open: boolean) => void;
  languages: LanguageInfo[];
  selectLocale: (lang: LanguageInfo) => void;
}

export const LanguageSelectionDialog: React.FC<LanguageDialogProps> = ({
  title,
  open,
  onOpenChange,
  languages,
  selectLocale,
}) => (
  <Dialog open={open} onOpenChange={onOpenChange}>
    <DialogContent className="sm:max-w-xl">
      <DialogHeader>
        <DialogTitle>{title}</DialogTitle>
      </DialogHeader>
      <div className="grid gap-4 py-4">
        <Command className="rounded-lg border shadow-md">
          <CommandInput placeholder="Search language..." />
          <CommandList>
            <CommandEmpty>No language found.</CommandEmpty>
            <CommandGroup>
              {languages.map((lang) => (
                <CommandItem
                  key={lang.code}
                  onSelect={() => selectLocale(lang)}
                  value={`${lang.localizedName} ${lang.nativeName}`}
                >
                  <LanguageDisplay
                    language={lang}
                    showNative={true}
                    showCountryName={true}
                  />
                </CommandItem>
              ))}
            </CommandGroup>
          </CommandList>
        </Command>
      </div>
      <DialogFooter>
        <Button variant="outline" onClick={() => onOpenChange(false)}>
          Cancel
        </Button>
      </DialogFooter>
    </DialogContent>
  </Dialog>
);
