import { MagnifyingGlassIcon } from "@heroicons/react/24/solid";
import { useEffect, useState } from "react";

import { useCurrentProjectBreadcrumbs } from "@/hooks";

import QueryDateRangePicker from "../QueryDateRangePicker";
import NavigationHeader from "../sidebar/NavigationHeader";
import PageHeader from "../PageHeader";
import {
  createEnumArrayHandler,
  nullableDateHandler,
  stringArrayHandler,
  stringHandler,
  useQueryParam,
  useQueryState,
} from "@/hooks/useQueryState";
import { searchMessagesOptions } from "@/client/@tanstack/react-query.gen";
import { useQuery } from "@tanstack/react-query";
import { AxiosErrorBox } from "../Error";
import { LoadingMessageList, MessageList } from "./MessageList";
import { Input } from "../ui/input";

import { AdvancedFilter } from "./MessageFilter";
import SearchHelpDialog from "./SearchHelpDialog";
import ActiveFilters from "./ActiveFilters";
import { useParams } from "react-router-dom";
import { HandoffStatusSchema } from "@/client";

function MessageSearchPage() {
  const { projectId } = useParams();

  const [since] = useQueryState("since", null, nullableDateHandler);
  const [until] = useQueryState("until", null, nullableDateHandler);
  const [aliases] = useQueryState("alias", [], stringArrayHandler);
  const [fullTextSearch, setFullTextSearch] = useQueryState(
    "query",
    "",
    stringHandler
  );

  const rating = useQueryParam("rating", [], stringArrayHandler);
  const completeness = useQueryParam("completeness", [], stringArrayHandler);
  const sentiment = useQueryParam("sentiment", [], stringArrayHandler);
  const languages = useQueryParam("languages", [], stringArrayHandler);
  const pages = useQueryParam("pages", [], stringArrayHandler);
  const handoff = useQueryParam(
    "handoff",
    [],
    createEnumArrayHandler(HandoffStatusSchema.enum)
  );

  // Debounce state for fullTextSearch
  const [debouncedFullTextSearch, setDebouncedFullTextSearch] =
    useState(fullTextSearch);
  const [isDebouncing, setIsDebouncing] = useState(false);

  useEffect(() => {
    setIsDebouncing(true);
    const handler = setTimeout(() => {
      setDebouncedFullTextSearch(fullTextSearch);
      setIsDebouncing(false);
    }, 500);

    return () => {
      clearTimeout(handler);
      setIsDebouncing(false);
    };
  }, [fullTextSearch]);

  const { data, isPending, error } = useQuery({
    ...searchMessagesOptions({
      path: {
        project_id: Number(projectId),
      },
      body: {
        project_id: Number(projectId),
        since: since!,
        until: until!,
        text: debouncedFullTextSearch || "",
        aliases: aliases,
        tags: [
          ...completeness.map((value) => ({
            name: "nlp:assistant.completeness",
            value,
          })),
          ...rating.map((value) => ({ name: "chat:rating", value })),
          ...sentiment.map((value) => ({ name: "nlp:user.sentiment", value })),
          ...languages.map((value) => ({ name: "nlp:user.language", value })),
          ...pages.map((value) => ({ name: "chat:website:url", value })),
        ],
        handoff: handoff,
        limit: 200,
        offset: 0,
      },
    }),
    enabled: !!since && !!until && !isDebouncing,
  });

  const breadcrumbs = [
    ...useCurrentProjectBreadcrumbs(),
    { label: "Messages" },
  ];

  if (error) return <AxiosErrorBox error={error} />;

  const messages = data;

  return (
    <>
      <NavigationHeader items={breadcrumbs} />
      <PageHeader
        title="Search Messages"
        subtitle="Deeply search messages to find relevant conversations with full text search and filters."
        children={<AdvancedFilter />}
      />
      <main>
        <div className="mt-4 p-2">
          <div className="space flex w-full flex-row flex-wrap justify-between gap-4 sm:flex-nowrap">
            <div className="flex w-full flex-row">
              <div className="relative flex w-full max-w-sm items-center">
                <Input
                  type="text"
                  placeholder="Search..."
                  value={fullTextSearch || ""}
                  onChange={(e) => setFullTextSearch(e.target.value)}
                  className="pr-8"
                />
                <div className="absolute right-2">
                  <MagnifyingGlassIcon className="h-4 w-4 text-gray-400" />
                </div>
              </div>
              <div className="flex justify-center items-center">
                <SearchHelpDialog />
              </div>
            </div>

            <div className="w-full sm:w-auto">
              <QueryDateRangePicker />
            </div>
          </div>
          <ActiveFilters />
          {isDebouncing || isPending || messages === undefined ? (
            <LoadingMessageList />
          ) : (
            <MessageList messages={messages} />
          )}
        </div>
      </main>
    </>
  );
}

export default MessageSearchPage;
