import { useCurrentProjectBreadcrumbs } from "../../hooks";
import { WeeklyMessagesHeatmap, YearlyMessagesHeatmap } from "./Heatmaps";
import { TripletMetricsCard } from "./MetricCards";
import QueryDateRangePicker from "../QueryDateRangePicker";
import QueryDeploymentAliasPicker from "../QueryDeploymentAliasPicker";
import NavigationHeader from "../sidebar/NavigationHeader";
import PageHeader from "../PageHeader";
import HandoffChart from "./HandoffChart";
import UserRatingChart from "./UserRatingChart";
import UserSentimentChart from "./UserSentimentChart";
import AnswerCompletenessChart from "./AnswerCompletenessChart";
import ConversationLengthChart from "./ConversationLengthChart";
import UserLanguageChart from "./UserLanguageChart";
import UsageByPageChart from "./UsagePyPageChart";

export default function ProjectMetrics() {
  const breadcrumbs = [...useCurrentProjectBreadcrumbs(), { label: "Metrics" }];

  return (
    <>
      <NavigationHeader items={breadcrumbs} />
      <PageHeader title="Metrics" />
      <div className="mb-4">
        <div className="mt-2 flex flex-col lg:flex-row justify-between gap-4">
          <div className="w-full sm:w-[384px]">
            <QueryDeploymentAliasPicker />
          </div>
          <QueryDateRangePicker />
        </div>
      </div>

      <main className="pb-24">
        <div className="space-y-6">
          <TripletMetricsCard lastDays={undefined} />
          <WeeklyMessagesHeatmap />
          <div className="grid md:grid-cols-2 lg:grid-cols-3 gap-6 mt-6">
            <AnswerCompletenessChart />
            <UserSentimentChart />
            <UserRatingChart />
          </div>
          <div className="grid md:grid-cols-1 lg:grid-cols-2 gap-6">
            <UserLanguageChart />
            <UsageByPageChart />
          </div>
          <HandoffChart />
          <ConversationLengthChart />
          <YearlyMessagesHeatmap />
        </div>
      </main>
    </>
  );
}
