import { Info, Rocket } from "lucide-react";
import {
  Dialog,
  DialogContent,
  DialogHeader,
  DialogTitle,
  DialogTrigger,
} from "@/components/ui/dialog";
import { Button } from "@/components/ui/button";

export default function SearchHelpDialog() {
  return (
    <Dialog>
      <DialogTrigger asChild>
        <Button variant="ghost" size="icon" className="h-9 w-9">
          <Info className="h-5 w-5 text-gray-400 hover:text-emerald-600" />
        </Button>
      </DialogTrigger>
      <DialogContent className="sm:max-w-md">
        <DialogHeader>
          <div className="flex items-center gap-2">
            <Rocket className="h-6 w-6" />
            <DialogTitle>Full Text Search</DialogTitle>
          </div>
        </DialogHeader>
        <div className="space-y-4">
          <p>
            Our search supports advanced full-text search capabilities. Here are
            some examples of how you can use it:
          </p>

          <ul className="space-y-3">
            <li>
              <p className="font-semibold">star wars</p>
              <p className="text-sm text-muted-foreground">
                Search for messages containing both "star" and "wars".
              </p>
            </li>
            <li>
              <p className="font-semibold">star or wars</p>
              <p className="text-sm text-muted-foreground">
                Search for messages containing either "star" or "wars".
              </p>
            </li>
            <li>
              <p className="font-semibold">star -wars</p>
              <p className="text-sm text-muted-foreground">
                Search for messages containing "star" but not "wars".
              </p>
            </li>
            <li>
              <p className="font-semibold">"star wars"</p>
              <p className="text-sm text-muted-foreground">
                Search for messages containing the exact phrase "star wars".
              </p>
            </li>
          </ul>
        </div>
      </DialogContent>
    </Dialog>
  );
}
