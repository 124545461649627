import { formatDistanceToNowStrict } from "date-fns";
import { Bot, User } from "lucide-react";

import { MessageRead } from "@/client/types.gen";
import { Card, CardContent, CardHeader } from "@/components/ui/card";

import Empty from "../Empty";
import LinkWithQuery from "../LinkWithQuery";
import { Skeleton } from "../ui/skeleton";

export function MessageList({ messages }: { messages: MessageRead[] }) {
  if (messages.length === 0) {
    return (
      <div className="mt-12">
        <Empty message="No messages found." />
      </div>
    );
  }

  const sortedMessages = [...messages].sort(
    (a, b) =>
      new Date(b.created_at).getTime() - new Date(a.created_at).getTime()
  );

  return (
    <div className="grid grid-cols-1 gap-4 mt-2 sm:grid-cols-2 lg:grid-cols-3">
      {sortedMessages.map((message) => (
        <LinkWithQuery
          to={`/${message.project_id}/conversations/${message.conversation_id}?message_id=${message.id}`}
          key={message.id}
          className="no-underline"
        >
          <Card className="h-full transition-all hover:shadow-md">
            <CardHeader className="flex-row items-center justify-between space-y-0 pb-2">
              <div className="flex items-center space-x-2 text-sm text-muted-foreground">
                <time>
                  {formatDistanceToNowStrict(new Date(message.created_at), {
                    addSuffix: true,
                  })}
                </time>
                <span>•</span>
                <div className="flex items-center space-x-1">
                  {message.role === "user" ? (
                    <User className="h-4 w-4 text-orange-500" />
                  ) : (
                    <Bot className="h-4 w-4 text-primary" />
                  )}
                  <span className="capitalize">{message.role}</span>
                </div>
              </div>
            </CardHeader>
            <CardContent>
              <p
                className={`line-clamp-3 ${
                  message.role === "user"
                    ? "text-sm font-medium"
                    : "text-sm text-muted-foreground"
                }`}
              >
                {message.text}
              </p>
            </CardContent>
          </Card>
        </LinkWithQuery>
      ))}
    </div>
  );
}

export const LoadingMessageList = () => {
  const skeletons = Array.from({ length: 6 }, (_, i) => i);
  return (
    <div className="grid grid-cols-1 gap-4 mt-2 sm:grid-cols-2 lg:grid-cols-3">
      {skeletons.map((index) => (
        <Card key={index} className="h-full">
          <CardHeader className="flex-row items-center justify-between space-y-0 pb-2">
            <div className="flex items-center space-x-2">
              {/* Time skeleton */}
              <Skeleton className="h-4 w-24" />
              <span></span>
              {/* Role skeleton with icon and text */}
              <div className="flex items-center space-x-1">
                <Skeleton className="h-4 w-4 rounded-full" />
                <Skeleton className="h-4 w-16" />
              </div>
            </div>
          </CardHeader>
          <CardContent>
            {/* Text content skeleton - three lines */}
            <div className="space-y-2">
              <Skeleton className="h-4 w-full" />
              <Skeleton className="h-4 w-5/6" />
              <Skeleton className="h-4 w-4/6" />
            </div>
          </CardContent>
        </Card>
      ))}
    </div>
  );
};
