import { useQuery } from "@tanstack/react-query";
import { useState } from "react";
import { listTablesOptions } from "@/client/@tanstack/react-query.gen";
import { Button } from "@/components/ui/button";
import {
  Dialog,
  DialogContent,
  DialogHeader,
  DialogTitle,
  DialogTrigger,
} from "@/components/ui/dialog";
import {
  Card,
  CardHeader,
  CardTitle,
  CardDescription,
} from "@/components/ui/card";
import { Loader2 } from "lucide-react";

interface SearchTableDialogProps {
  projectId: string;
  onTableSelect: (tableData: { id: string; name: string }) => void;
  disabled?: boolean;
}

const SelectSearchTable = ({
  projectId,
  onTableSelect,
  disabled,
}: SearchTableDialogProps) => {
  const [isOpen, setIsOpen] = useState(false);

  const { data: tables, isLoading } = useQuery({
    ...listTablesOptions({
      path: { project_id: Number(projectId) },
    }),
  });

  const handleTableSelect = (table: { id: string; name: string }) => {
    onTableSelect(table);
    setIsOpen(false);
  };

  return (
    <Dialog open={isOpen} onOpenChange={setIsOpen}>
      <DialogTrigger asChild>
        <Button type="button" variant="outline" disabled={disabled}>
          Add Search Table
        </Button>
      </DialogTrigger>
      <DialogContent className="max-w-3xl max-h-[80vh] overflow-y-auto">
        <DialogHeader>
          <DialogTitle>Select a Search Table</DialogTitle>
        </DialogHeader>
        {isLoading ? (
          <div className="flex justify-center p-4">
            <Loader2 className="h-6 w-6 animate-spin text-gray-400" />
          </div>
        ) : (
          <div className="grid gap-4 md:grid-cols-2 lg:grid-cols-3">
            {tables?.data?.map((table) => (
              <Card
                key={table.id}
                className="hover:shadow-lg transition-shadow cursor-pointer"
                onClick={() =>
                  handleTableSelect({ id: table.id, name: table.name })
                }
              >
                <CardHeader>
                  <CardTitle className="text-lg hover:text-blue-600">
                    {table.name}
                  </CardTitle>
                  <CardDescription className="text-sm text-gray-500">
                    ID: {table.id}
                  </CardDescription>
                </CardHeader>
              </Card>
            ))}
          </div>
        )}
      </DialogContent>
    </Dialog>
  );
};

export default SelectSearchTable;
