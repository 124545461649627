import { useNavigate, useParams } from "react-router-dom";
import { DonutChart } from "../tremor/DonutChart";
import { Card, CardContent, CardHeader, CardTitle } from "../ui/card";
import { histogramToAggregatedData } from "./helper";
import { useHistogram } from "./hooks";
import { AxiosErrorBox } from "../Error";
import { Skeleton } from "../ui/skeleton";

export default function UserSentimentChart() {
  const { projectId } = useParams();
  const navigate = useNavigate();
  const {
    data: chartData,
    keys,
    isPending,
    error,
  } = useHistogram("UserSentiment", "1d");
  const donutData = histogramToAggregatedData(chartData, keys);

  const categoryOrder = ["Positive", "Neutral", "Negative"];

  const orderedDonutData = [...donutData].sort(
    (a, b) => categoryOrder.indexOf(a.name) - categoryOrder.indexOf(b.name)
  );

  if (error) return AxiosErrorBox({ error });

  return (
    <Card>
      <CardHeader>
        <CardTitle className="text-lg  font-semibold">User Sentiment</CardTitle>
      </CardHeader>
      <CardContent>
        {isPending ? (
          <Skeleton className="h-40" />
        ) : (
          <DonutChart
            className="mx-auto"
            data={orderedDonutData}
            category="name"
            value="value"
            colorMap={
              new Map([
                ["Positive", "#4ade80"], // green-400
                ["Negative", "#f87171"], // red-400
                ["Neutral", "#9ca3af"], // gray-400
              ])
            }
            valueFormatter={(value) =>
              value == 1 ? `${value} message` : `${value} messages`
            }
            showLabel={true}
            onValueChange={(value) => {
              if (!value) return;
              navigate(
                `/${projectId}/messages/search?sentiment=${value.categoryClicked}`
              );
            }}
          />
        )}
      </CardContent>
    </Card>
  );
}
