import {
  subDays,
  startOfMonth,
  startOfYear,
  format,
  setMilliseconds,
  setSeconds,
  setMinutes,
  setHours,
} from "date-fns";
import { CalendarIcon, ChevronDown } from "lucide-react";
import { DateRange } from "react-day-picker";
import { cn } from "@/lib/utils";
import { Button } from "@/components/ui/button";
import { Calendar } from "@/components/ui/calendar";
import {
  Popover,
  PopoverContent,
  PopoverTrigger,
} from "@/components/ui/popover";
import {
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from "@/components/ui/select";
import { useSearchParams } from "react-router-dom";
import { useEffect, useState } from "react";

const startOfDay = (date: Date) => {
  return setMilliseconds(setSeconds(setMinutes(setHours(date, 0), 0), 0), 0);
};

const endOfDay = (date: Date) => {
  return setMilliseconds(
    setSeconds(setMinutes(setHours(date, 23), 59), 59),
    999
  );
};

const PRESETS = {
  today: {
    label: "Today",
    getValue: (today: Date) => startOfDay(today),
  },
  last7: {
    label: "Last 7 days",
    getValue: (today: Date) => startOfDay(subDays(today, 7)),
  },
  last30: {
    label: "Last 30 days",
    getValue: (today: Date) => startOfDay(subDays(today, 30)),
  },
  last90: {
    label: "Last 90 days",
    getValue: (today: Date) => startOfDay(subDays(today, 90)),
  },
  mtd: {
    label: "Month to date",
    getValue: (today: Date) => startOfMonth(today),
  },
  ytd: {
    label: "Year to date",
    getValue: (today: Date) => startOfYear(today),
  },
} as const;

interface QueryDateRangePickerProps {
  className?: string;
  disabled?: boolean;
  prefix?: string;
}

export default function QueryDateRangePicker({
  className,
  disabled = false,
}: QueryDateRangePickerProps) {
  const [searchParams, setSearchParams] = useSearchParams();

  const since = searchParams.get(`since`);
  const until = searchParams.get(`until`);
  const today = endOfDay(new Date());
  const [date, _setDate] = useState<DateRange | undefined>({
    from: since ? new Date(since) : PRESETS.last30.getValue(today),
    to: until ? new Date(until) : today,
  });

  // Inject correct time to date range for valid values
  const setDate = (date: DateRange | undefined) => {
    if (!date) {
      _setDate(undefined);
      return;
    }
    const newRange: DateRange = {
      from: date.from ? startOfDay(date.from) : undefined,
      to: date.to ? endOfDay(date.to) : undefined,
    };
    _setDate(newRange);
  };

  useEffect(() => {
    if (!date?.from || !date?.to) return;

    setSearchParams(
      (prev) => {
        const params = new URLSearchParams(prev);
        params.set(`since`, date.from!.toISOString());
        params.set(`until`, date.to!.toISOString());
        console.log(date, params);
        return params;
      },
      { replace: true }
    );
  }, [date, setSearchParams]);

  const [isCalendarOpen, setIsCalendarOpen] = useState(false);

  const handlePresetChange = (value: keyof typeof PRESETS) => {
    if (disabled) return;
    setDate({
      from: PRESETS[value].getValue(today),
      to: today,
    });
  };

  const getCurrentPreset = (): string => {
    if (!date?.from || !date?.to) return "";
    for (const [, preset] of Object.entries(PRESETS)) {
      if (
        format(preset.getValue(today), "yyyy-MM-dd") ===
          format(date.from, "yyyy-MM-dd") &&
        format(today, "yyyy-MM-dd") === format(date.to, "yyyy-MM-dd")
      ) {
        return preset.label;
      }
    }
    return "Custom Range";
  };

  return (
    <div
      className={cn(
        "flex flex-col lg:flex-row",
        disabled && "cursor-not-allowed",
        className
      )}
    >
      {/* Desktop View - Combined Component */}
      <div className="hidden lg:flex rounded-lg border shadow-sm">
        {/* Date Display/Calendar Trigger */}
        <Popover
          open={isCalendarOpen}
          onOpenChange={(open) => !disabled && setIsCalendarOpen(open)}
        >
          <PopoverTrigger asChild>
            <Button
              variant="ghost"
              className={cn(
                "px-3 py-2 h-auto rounded-l-lg rounded-r-none border-r text-muted-foreground",
                !date && "text-muted-foreground",
                disabled && "opacity-50"
              )}
              disabled={disabled}
            >
              <CalendarIcon className="mr-2 h-4 w-4" />
              {date?.from ? (
                date.to ? (
                  <>
                    {format(date.from, "MMM dd, yyyy")} -{" "}
                    {format(date.to, "MMM dd, yyyy")}
                  </>
                ) : (
                  format(date.from, "MMM dd, yyyy")
                )
              ) : (
                <span>Pick a date range</span>
              )}
            </Button>
          </PopoverTrigger>
          <PopoverContent className="w-auto p-3" align="start">
            <Calendar
              initialFocus
              mode="range"
              defaultMonth={date?.from}
              selected={date}
              onSelect={(dateRange: DateRange | undefined) => {
                if (disabled) return;
                setDate(dateRange);
                if (dateRange?.from && dateRange?.to) {
                  setIsCalendarOpen(false);
                }
              }}
              numberOfMonths={2}
              className={cn(
                "p-0",
                disabled && "pointer-events-none opacity-50"
              )}
              disabled={disabled}
            />
          </PopoverContent>
        </Popover>

        {/* Quick Preset Selector */}
        <Select onValueChange={handlePresetChange} disabled={disabled}>
          <SelectTrigger
            className={cn(
              "w-[140px] border-0 rounded-l-none rounded-r-lg focus:ring-0 focus:ring-offset-0 text-muted-foreground",
              disabled && "opacity-50"
            )}
          >
            <SelectValue placeholder={getCurrentPreset() || "Select range"} />
          </SelectTrigger>
          <SelectContent>
            {Object.entries(PRESETS).map(([key, { label }]) => (
              <SelectItem key={key} value={key}>
                {label}
              </SelectItem>
            ))}
          </SelectContent>
        </Select>
      </div>

      {/* Mobile View - Single Button with Popover */}
      <div className="lg:hidden w-full">
        <Popover>
          <PopoverTrigger asChild>
            <Button
              variant="outline"
              className={cn(
                "w-full justify-between text-muted-foreground",
                disabled && "opacity-50"
              )}
              disabled={disabled}
            >
              <span className="flex items-center">
                <CalendarIcon className="mr-2 h-4 w-4" />
                {date?.from ? (
                  date.to ? (
                    <>
                      {format(date.from, "MMM dd, yyyy")} -{" "}
                      {format(date.to, "MMM dd, yyyy")}
                    </>
                  ) : (
                    format(date.from, "MMM dd, yyyy")
                  )
                ) : (
                  <span>Pick a date range</span>
                )}
              </span>
              <ChevronDown className="h-4 w-4 opacity-50" />
            </Button>
          </PopoverTrigger>
          <PopoverContent className="w-full p-3" align="start">
            <Select onValueChange={handlePresetChange} disabled={disabled}>
              <SelectTrigger
                className={cn(
                  "w-full mb-2 text-muted-foreground",
                  disabled && "opacity-50"
                )}
              >
                <SelectValue placeholder="Select preset range" />
              </SelectTrigger>
              <SelectContent>
                {Object.entries(PRESETS).map(([key, { label }]) => (
                  <SelectItem key={key} value={key}>
                    {label}
                  </SelectItem>
                ))}
              </SelectContent>
            </Select>
            <div className="border rounded-md mt-2">
              <Calendar
                initialFocus
                mode="range"
                defaultMonth={date?.from}
                selected={date}
                onSelect={(dateRange: DateRange | undefined) =>
                  !disabled && setDate(dateRange)
                }
                numberOfMonths={1}
                className={cn(
                  "p-0",
                  disabled && "pointer-events-none opacity-50"
                )}
                disabled={disabled}
              />
            </div>
          </PopoverContent>
        </Popover>
      </div>
    </div>
  );
}
