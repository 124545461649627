import { useNavigate, useParams } from "react-router-dom";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import { Loader2, Save, Trash2 } from "lucide-react";
import { toast } from "sonner";

import { Button } from "@/components/ui/button";
import {
  AlertDialog,
  AlertDialogAction,
  AlertDialogCancel,
  AlertDialogContent,
  AlertDialogDescription,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogTitle,
  AlertDialogTrigger,
} from "@/components/ui/alert-dialog";
import {
  deleteTableMutation,
  updateTableMutation,
  listTablesOptions,
  getSearchOptionsOptions,
  getTableContentOptions,
} from "@/client/@tanstack/react-query.gen";
import { DataTypes, SearchFieldType } from "@/client";

interface TableActionsProps {
  getTableConfig: () => {
    name: string;
    fields: Array<{
      name: string;
      data_type: DataTypes;
      search_type: SearchFieldType;
      accessor: string;
    }>;
    objects: any[];
  };
}

export const TableActions = ({ getTableConfig }: TableActionsProps) => {
  const { projectId, tableId } = useParams();
  const navigate = useNavigate();
  const queryClient = useQueryClient();

  const deleteTable = useMutation({
    ...deleteTableMutation(),
    onSuccess: () => {
      toast.success("Table deleted successfully");
      navigate(`/${projectId}/tables`);
      queryClient.invalidateQueries({
        queryKey: listTablesOptions({
          path: { project_id: Number(projectId) },
        }).queryKey,
      });
    },
    onError: (error) => {
      toast.error("Failed to delete table: " + error.message);
    },
  });

  const updateTable = useMutation({
    ...updateTableMutation(),
    onSuccess: () => {
      toast.success("Table updated successfully");
      // Invalidate table
      setTimeout(() => {
        const getTableConfig = getTableContentOptions({
          path: {
            project_id: Number(projectId),
            table_id: tableId!,
          },
        }).queryKey;
        queryClient.invalidateQueries({
          queryKey: getTableConfig,
        });

        // Invalidate search
        const searchQueryKey = getSearchOptionsOptions({
          path: {
            project_id: Number(projectId),
            table_id: tableId!,
          },
        }).queryKey;
        queryClient.invalidateQueries({ queryKey: searchQueryKey });
      }, 300); // need small delay to allow table to be updated in S3 (s3/minio have strong-read-after-write consistency for new objects and since Dez 2020, also for updates)
    },
    onError: (error) => {
      toast.error("Failed to update table: " + error.message);
    },
  });

  const handleDelete = () => {
    deleteTable.mutate({
      path: {
        project_id: Number(projectId),
        table_id: tableId!,
      },
    });
  };

  const handleUpdate = () => {
    const config = getTableConfig();
    console.log("Updating table", config);
    updateTable.mutate({
      path: {
        project_id: Number(projectId),
        table_id: tableId!,
      },
      body: {
        config,
      },
    });
  };

  return (
    <div className="flex gap-2">
      <Button
        variant="default"
        onClick={handleUpdate}
        disabled={updateTable.isPending}
      >
        {updateTable.isPending ? (
          <>
            <Loader2 className="mr-2 h-4 w-4 animate-spin" />
            Saving...
          </>
        ) : (
          <>
            <Save className="mr-2 h-4 w-4" />
            Save Changes
          </>
        )}
      </Button>

      <AlertDialog>
        <AlertDialogTrigger asChild>
          <Button variant="destructive" disabled={deleteTable.isPending}>
            {deleteTable.isPending ? (
              <>
                <Loader2 className="mr-2 h-4 w-4 animate-spin" />
                Deleting...
              </>
            ) : (
              <>
                <Trash2 className="mr-2 h-4 w-4" />
                Delete Table
              </>
            )}
          </Button>
        </AlertDialogTrigger>
        <AlertDialogContent>
          <AlertDialogHeader>
            <AlertDialogTitle>Are you absolutely sure?</AlertDialogTitle>
            <AlertDialogDescription>
              This action cannot be undone. This will permanently delete the
              table and all of its data.
            </AlertDialogDescription>
          </AlertDialogHeader>
          <AlertDialogFooter>
            <AlertDialogCancel>Cancel</AlertDialogCancel>
            <AlertDialogAction
              onClick={handleDelete}
              className="bg-red-600 hover:bg-red-700"
            >
              Delete
            </AlertDialogAction>
          </AlertDialogFooter>
        </AlertDialogContent>
      </AlertDialog>
    </div>
  );
};

export default TableActions;
