import type { LanguageInfo } from "@/ietf-bcp-47";
import React from "react";

interface LanguageDisplayProps {
  language: LanguageInfo;
  showCountryName: boolean;
  showNative: boolean;
}
export const LanguageDisplay: React.FC<LanguageDisplayProps> = ({
  language,
  showCountryName,
  showNative,
}) => (
  <span className="flex items-center gap-2">
    <span>{language.emoji}</span>
    <span>
      {!showCountryName && language.shortLocalizedName}
      {showCountryName && language.localizedName}
      {showNative && language.shortLocalizedName !== language.nativeName && (
        <span className="ml-1 text-muted-foreground">
          ({language.nativeName})
        </span>
      )}
    </span>
  </span>
);
