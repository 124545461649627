import { useCallback, useContext, useEffect, useRef } from "react";
import {
  useSearchParams,
  useNavigate,
  UNSAFE_NavigationContext,
  UNSAFE_DataRouterContext,
} from "react-router-dom";
import { z } from "zod";

interface TypeHandler<T> {
  parse: (param: string) => T;
  serialize: (value: T) => string;
}

interface ArrayTypeHandler<T> {
  parse: (params: string[]) => T;
  serialize: (value: T) => string[];
}

// Helper function to create zod-based type handler
export function createZodHandler<T>(schema: z.ZodType<T>): TypeHandler<T> {
  return {
    parse: (param: string): T => {
      const decoded = decodeURIComponent(param);
      const parsed = JSON.parse(decoded);
      return schema.parse(parsed);
    },
    serialize: (value: T): string => encodeURIComponent(JSON.stringify(value)),
  };
}

// Helper function to create enum type handler
export function createEnumHandler<T extends string>(
  validValues: readonly T[]
): TypeHandler<T> {
  return {
    parse: (param: string): T => {
      const decoded = decodeURIComponent(param);
      if (validValues.includes(decoded as T)) {
        return decoded as T;
      }
      throw new Error(`Invalid enum value: ${decoded}`);
    },
    serialize: (value: T): string => {
      if (!validValues.includes(value)) {
        throw new Error(`Invalid enum value: ${value}`);
      }
      return encodeURIComponent(value);
    },
  };
}

export function createEnumArrayHandler<T extends string>(
  validValues: readonly T[]
): ArrayTypeHandler<T[]> {
  return {
    parse: (params: string[]): T[] => {
      return params.map((param) => {
        const decoded = decodeURIComponent(param);
        if (validValues.includes(decoded as T)) {
          return decoded as T;
        }
        throw new Error(`Invalid enum value: ${decoded}`);
      });
    },
    serialize: (value: T[]): string[] => {
      value.forEach((item) => {
        if (!validValues.includes(item)) {
          throw new Error(`Invalid enum value: ${item}`);
        }
      });
      return value.map((item) => encodeURIComponent(item));
    },
  };
}

export const numberHandler: TypeHandler<number> = {
  parse: (param: string): number => {
    const decoded = decodeURIComponent(param);
    const parsed = Number(decoded);
    if (isNaN(parsed)) throw new Error("Invalid number format");
    return parsed;
  },
  serialize: (value: number): string => encodeURIComponent(value.toString()),
};

export const stringHandler: TypeHandler<string> = {
  parse: (param: string): string => decodeURIComponent(param),
  serialize: (value: string): string => encodeURIComponent(value),
};

export const stringArrayHandler: ArrayTypeHandler<string[]> = {
  parse: (params: string[]): string[] => {
    return params.map((param) => decodeURIComponent(param));
  },
  serialize: (value: string[]): string[] => {
    return value.map((item) => encodeURIComponent(item));
  },
};

export const numberArrayHandler: ArrayTypeHandler<number[]> = {
  parse: (params: string[]): number[] => {
    return params.map((param) => {
      const decoded = decodeURIComponent(param);
      const parsed = Number(decoded);
      if (isNaN(parsed)) throw new Error(`Invalid number format: ${decoded}`);
      return parsed;
    });
  },
  serialize: (value: number[]): string[] => {
    return value.map((item) => encodeURIComponent(item.toString()));
  },
};

export const booleanHandler: TypeHandler<boolean> = {
  parse: (param: string): boolean => {
    const decoded = decodeURIComponent(param);
    if (decoded !== "true" && decoded !== "false") {
      throw new Error("Invalid boolean format");
    }
    return decoded === "true";
  },
  serialize: (value: boolean): string => encodeURIComponent(value.toString()),
};

export const dateHandler: TypeHandler<Date> = {
  parse: (param: string): Date => {
    const decoded = decodeURIComponent(param);
    const parsed = new Date(decoded);
    if (isNaN(parsed.getTime())) {
      throw new Error("Invalid date format");
    }
    return parsed;
  },
  serialize: (value: Date): string => encodeURIComponent(value.toISOString()),
};

export function jsonHandler<T>(): TypeHandler<T> {
  return {
    parse: (param: string): T => JSON.parse(decodeURIComponent(param)),
    serialize: (value: T): string => encodeURIComponent(JSON.stringify(value)),
  };
}

const NULL_PARAM_TOKEN = "__NULL__";

export function createNullableHandler<T>(
  baseHandler: TypeHandler<T>
): TypeHandler<T | null> {
  return {
    parse: (param: string): T | null => {
      if (param === NULL_PARAM_TOKEN) {
        return null;
      }
      try {
        return baseHandler.parse(param);
      } catch {
        return null;
      }
    },
    serialize: (value: T | null): string => {
      if (value === null) {
        return NULL_PARAM_TOKEN;
      }
      return baseHandler.serialize(value);
    },
  };
}

export function createNullableArrayHandler<T>(
  baseHandler: ArrayTypeHandler<T>
): ArrayTypeHandler<T | null> {
  return {
    parse: (params: string[]): T | null => {
      if (params[0] === NULL_PARAM_TOKEN) return null;
      return baseHandler.parse(params);
    },
    serialize: (value: T | null): string[] => {
      if (value === null) return [NULL_PARAM_TOKEN];
      return baseHandler.serialize(value);
    },
  };
}

export const nullableNumberHandler = createNullableHandler(numberHandler);
export const nullableStringHandler = createNullableHandler(stringHandler);
export const nullableStringArrayHandler =
  createNullableArrayHandler(stringArrayHandler);
export const nullableNumberArrayHandler =
  createNullableArrayHandler(numberArrayHandler);
export const nullableBooleanHandler = createNullableHandler(booleanHandler);
export const nullableDateHandler = createNullableHandler(dateHandler);

/**
 * A React hook that synchronizes state with URL search parameters, similar to useState but persisting the state in the URL.
 * This allows for shareable URLs that preserve UI state across page loads and between different users.
 *
 * Features:
 * - Type-safe implementation using TypeScript generics
 * - Automatic cleanup of URL parameters when component unmounts
 * - Removes parameters from URL when value equals default value
 * - Supports both direct values and updater functions like useState
 * - Falls back to default value if parsing fails
 *
 * @template T - The type of the state value
 * @param name - The name of the URL search parameter
 * @param defaultValue - The default value when the parameter is not present or invalid
 * @param handler - An object containing parse and serialize functions for type conversion
 * @returns A tuple containing the current value and a setter function, similar to useState
 *
 * @example
 * ```typescript
 * // Basic usage with a number
 * const [count, setCount] = useQueryState('count', 0, numberHandler);
 *
 * // Usage with an updater function
 * setCount(prev => prev + 1);
 *
 * // Complex object with Zod schema
 * const [filters, setFilters] = useQueryState(
 *   'filters',
 *   { search: '', page: 1 },
 *   createZodHandler(FiltersSchema)
 * );
 *
 * // Array usage with multiple parameters
 * const [topics, setTopics] = useQueryState('topic', [], stringArrayHandler);
 * // Will create URLs like: ?topic=cooking&topic=sports&topic=travel
 * ```
 */
export function useQueryState<T>(
  name: string,
  defaultValue: T,
  handler: TypeHandler<T> | ArrayTypeHandler<T>
): [T, (newValue: T | ((prev: T) => T)) => void] {
  const [, setSearchParams] = useSearchParams();
  const navigate = useNavigate();
  // Access router state directly for concurrent updates
  // taken from https://github.com/pbeshai/use-query-params/blob/b14c97ec2e7b1dfaca51d4d17439f9c306b34dba/packages/use-query-params-adapter-react-router-6/src/index.ts#L19
  // we need the navigator directly so we can access the current version
  // of location in case of multiple updates within a render (e.g. #233)
  // but we will limit our usage of it and have a backup to just use
  // useLocation() output in case of some kind of breaking change we miss.
  // see: https://github.com/remix-run/react-router/blob/f3d87dcc91fbd6fd646064b88b4be52c15114603/packages/react-router-dom/index.tsx#L113-L131
  const { navigator } = useContext(UNSAFE_NavigationContext);
  const router = useContext(UNSAFE_DataRouterContext)?.router;

  // Get the most up-to-date location including pending updates
  const getLocation = useCallback(() => {
    return (
      router?.state?.location ?? (navigator as any)?.location ?? window.location
    );
  }, [navigator, router]);

  // Get current search params including pending updates
  const getCurrentSearchParams = useCallback(() => {
    const location = getLocation();
    return new URLSearchParams(location.search);
  }, [getLocation]);

  // Cleanup on unmount + navigation => for QueryWithLink
  const initialLocation = useRef(getLocation());
  useEffect(() => {
    return () => {
      const currentLocation = getLocation();
      if (initialLocation.current.pathname !== currentLocation.pathname) {
        const currentParams = getCurrentSearchParams();
        if (currentParams.has(name)) {
          const newParams = new URLSearchParams(currentParams);
          newParams.delete(name);
          navigate({ search: newParams.toString() }, { replace: true });
        }
      }
    };
  }, [name, navigate, getCurrentSearchParams, getLocation, router]);

  const getValue = useCallback((): T => {
    const currentParams = getCurrentSearchParams();

    // Handle array parameters
    if (
      "parse" in handler &&
      "serialize" in handler &&
      Array.isArray(defaultValue)
    ) {
      const arrayHandler = handler as ArrayTypeHandler<T>;
      const params = currentParams.getAll(name);
      if (params.length === 0) return defaultValue;
      try {
        return arrayHandler.parse(params) as T;
      } catch {
        return defaultValue;
      }
    }

    // Handle single value parameters
    const singleHandler = handler as TypeHandler<T>;
    const param = currentParams.get(name);
    if (param === null) return defaultValue;
    try {
      return singleHandler.parse(param);
    } catch {
      return defaultValue;
    }
  }, [getCurrentSearchParams, name, defaultValue, handler]);

  const setValue = useCallback(
    (newValue: T | ((prev: T) => T)) => {
      const actualValue =
        typeof newValue === "function"
          ? (newValue as (prev: T) => T)(getValue())
          : newValue;

      setSearchParams(
        (prevParams) => {
          const newParams = new URLSearchParams(getCurrentSearchParams());

          if (actualValue === defaultValue) {
            newParams.delete(name);
          } else {
            try {
              // Handle array parameters
              if (
                "parse" in handler &&
                "serialize" in handler &&
                Array.isArray(actualValue)
              ) {
                const arrayHandler = handler as ArrayTypeHandler<T>;
                newParams.delete(name); // Remove all existing values
                const serializedValues = arrayHandler.serialize(actualValue);
                serializedValues.forEach((value) =>
                  newParams.append(name, value)
                );
              } else {
                // Handle single value parameters
                const singleHandler = handler as TypeHandler<T>;
                newParams.set(name, singleHandler.serialize(actualValue));
              }
            } catch (error) {
              console.error(`Error serializing value for ${name}:`, error);
              return prevParams;
            }
          }

          return newParams;
        },
        { replace: false }
      );
    },
    [
      name,
      defaultValue,
      handler,
      getValue,
      setSearchParams,
      getCurrentSearchParams,
    ]
  );

  return [getValue(), setValue];
}

export function useQueryParam<T>(
  name: string,
  defaultValue: T,
  handler: TypeHandler<T> | ArrayTypeHandler<T>
): T {
  const [value] = useQueryState(name, defaultValue, handler);
  return value;
}
