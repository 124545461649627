import { Badge } from "@/components/ui/badge";
import { X } from "lucide-react";
import { Button } from "@/components/ui/button";
import { stringArrayHandler, useQueryState } from "@/hooks/useQueryState";

const ActiveFilters = () => {
  const [ratings, setRatings] = useQueryState("rating", [], stringArrayHandler);
  const [completeness, setCompleteness] = useQueryState(
    "completeness",
    [],
    stringArrayHandler
  );
  const [sentiment, setSentiment] = useQueryState(
    "sentiment",
    [],
    stringArrayHandler
  );
  const [languages, setLanguages] = useQueryState(
    "languages",
    [],
    stringArrayHandler
  );
  const [handoff, setHandoff] = useQueryState(
    "handoff",
    [],
    stringArrayHandler
  );
  const [pages, setPages] = useQueryState("pages", [], stringArrayHandler);

  const filterGroups = [
    { values: ratings, label: "Rating", setter: setRatings },
    { values: completeness, label: "Completeness", setter: setCompleteness },
    { values: sentiment, label: "Sentiment", setter: setSentiment },
    {
      values: languages,
      label: "Language",
      setter: setLanguages,
    },
    { values: pages, label: "Page", setter: setPages },
    { values: handoff, label: "Handoff", setter: setHandoff },
  ];

  const hasActiveFilters = filterGroups.some(
    (group) => group.values.length > 0
  );

  if (!hasActiveFilters) return null;

  return (
    <div className="flex flex-wrap gap-2 mt-2">
      {filterGroups.map(({ values, label, setter }) =>
        values.map((value) => (
          <Badge
            key={`${label}-${value}`}
            variant="secondary"
            className="flex items-center gap-1"
          >
            {label}: {value}
            <Button
              variant="ghost"
              size="sm"
              className="h-4 w-4 p-0 hover:bg-transparent"
              onClick={() => setter(values.filter((v) => v !== value))}
            >
              <X className="h-3 w-3" />
            </Button>
          </Badge>
        ))
      )}
    </div>
  );
};

export default ActiveFilters;
