import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import { Plus, Globe, Boxes, Telescope, FileSpreadsheet } from "lucide-react";
import { useState } from "react";
import { Link, useParams } from "react-router-dom";

import {
  listDataprovidersOptions,
  createDataproviderMutation,
} from "@/client/@tanstack/react-query.gen";
import { Button } from "@/components/ui/button";
import { Card, CardContent, CardHeader } from "@/components/ui/card";
import { Skeleton } from "@/components/ui/skeleton";
import { toast } from "sonner";

import DataProviderForm, { DataProviderValues } from "./DataProviderForm";
import ProviderGroup from "./DataProviderGroup";
import {
  AnyProviderRow,
  HumanProviderRow,
  WebCrawlerProviderRow,
} from "./DataProviderRow";
import { Dialog } from "../ui/dialog";
import NavigationHeader, {
  NavigationHeaderSkeleton,
} from "../sidebar/NavigationHeader";
import { useCurrentProjectBreadcrumbs } from "@/hooks";
import PageHeader, { PageHeaderSkeleton } from "../PageHeader";
import { Feature } from "@/features";

function ProviderRowSkeleton() {
  return (
    <div className="flex items-center justify-between border-b border-gray-100 hover:bg-gray-50 px-4 py-3">
      <div className="flex items-center space-x-3">
        <Skeleton className="h-6 w-6 rounded" />
        <div className="space-y-2">
          <Skeleton className="h-4 w-40" />
          <Skeleton className="h-3 w-24" />
        </div>
      </div>
      <div className="flex items-center space-x-2">
        <Skeleton className="h-8 w-8 rounded" />
        <Skeleton className="h-8 w-8 rounded" />
      </div>
    </div>
  );
}

function ProviderGroupSkeleton({ count = 3 }: { count?: number }) {
  return (
    <Card>
      <CardHeader className="flex flex-row items-center justify-between bg-gray-50 py-4">
        <div className="flex items-center space-x-3">
          <Skeleton className="h-5 w-5 rounded" />
          <Skeleton className="h-5 w-24" />
          <Skeleton className="h-5 w-8 rounded-full" />
        </div>
      </CardHeader>
      <CardContent className="p-0">
        {Array.from({ length: count }).map((_, index) => (
          <ProviderRowSkeleton key={index} />
        ))}
      </CardContent>
    </Card>
  );
}

function LoadingState() {
  return (
    <div className="container mx-auto p-6">
      <NavigationHeaderSkeleton />
      <PageHeaderSkeleton hasSubtitle />
      <div className="space-y-6 mt-4">
        <ProviderGroupSkeleton count={3} />
        <ProviderGroupSkeleton count={2} />
      </div>
    </div>
  );
}

export default function DataProviderDashboard() {
  const { projectId } = useParams();
  const queryClient = useQueryClient();
  const [isDialogOpen, setIsDialogOpen] = useState(false);

  const { data: providers, isLoading } = useQuery({
    ...listDataprovidersOptions({
      path: { project_id: Number(projectId) },
    }),
  });

  const createDataProvider = useMutation({
    ...createDataproviderMutation(),
    onSuccess: () => {
      toast("Success", {
        description: "Data provider created successfully",
      });
      void queryClient.invalidateQueries({
        queryKey: listDataprovidersOptions({
          path: { project_id: Number(projectId) },
        }).queryKey,
      });
      setIsDialogOpen(false);
    },
  });

  const handleSubmit = (values: DataProviderValues) => {
    console.log(values);
    createDataProvider.mutate({
      path: { project_id: Number(projectId) },
      body: values,
    });
  };

  const breadcrumbs = [
    ...useCurrentProjectBreadcrumbs(),
    { label: "Knowledge" },
  ];

  if (isLoading || providers === undefined) {
    return <LoadingState />;
  }

  // Group providers by type
  const webcrawlers = providers.data_providers.filter(
    (p) => p.data_provider_type === "webcrawler"
  );
  const manualUploads = providers.data_providers.filter(
    (p) => p.data_provider_type === "human"
  );

  const custom = providers.data_providers.filter(
    (p) => !["webcrawler", "human"].includes(p.data_provider_type)
  );

  return (
    <>
      <NavigationHeader items={breadcrumbs} />
      <div className="flex items-center justify-between">
        <PageHeader title="Knowledge" subtitle="Manage your chatbot sources" />
        <div className="flex flex-row gap-4">
          <Feature flag="search-tables">
            <Link to={`/${projectId}/tables`}>
              <Button type="button">
                <FileSpreadsheet className="h-4 w-4" />
                Tables
              </Button>{" "}
            </Link>
          </Feature>
          <Feature flag="data-provider-create">
            <Dialog open={isDialogOpen} onOpenChange={setIsDialogOpen}>
              <Button
                onClick={() => {
                  setIsDialogOpen(true);
                }}
                variant={"default"}
              >
                <Plus className="mr-2 h-4 w-4" />
                Add Content
              </Button>
              <DataProviderForm
                onSubmit={handleSubmit}
                isSubmitting={createDataProvider.isPending}
              />
            </Dialog>
          </Feature>
        </div>
      </div>
      <div className="space-y-6 mt-4">
        {webcrawlers.length > 0 && (
          <ProviderGroup
            title="Websites"
            icon={Globe}
            count={webcrawlers.length}
          >
            {webcrawlers.map((provider) => (
              <WebCrawlerProviderRow key={provider.id} provider={provider} />
            ))}
          </ProviderGroup>
        )}

        {manualUploads.length > 0 && (
          <ProviderGroup
            title="Collections"
            icon={Boxes}
            count={manualUploads.length}
          >
            {manualUploads.map((provider) => (
              <HumanProviderRow key={provider.id} provider={provider} />
            ))}
          </ProviderGroup>
        )}

        {custom.length > 0 && (
          <ProviderGroup title="Custom" icon={Telescope} count={custom.length}>
            {custom.map((provider) => (
              <AnyProviderRow key={provider.id} provider={provider} />
            ))}
          </ProviderGroup>
        )}
      </div>
    </>
  );
}
