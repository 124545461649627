import { DonutChart } from "../tremor/DonutChart";
import { Card, CardContent, CardHeader, CardTitle } from "../ui/card";
import { aggregateHistogramData, histogramToAggregatedData } from "./helper";
import { useHistogram } from "./hooks";
import { AxiosErrorBox } from "../Error";
import { Skeleton } from "../ui/skeleton";

const avg = (d: Record<string, number>) =>
  Object.entries(d).reduce((s, [k, v]) => s + Number(k) * v, 0) /
    Object.values(d).reduce((s, v) => s + v, 0) || 0;

export default function ConversationLengthChart() {
  const {
    data: chartData,
    keys,
    isPending,
    error,
  } = useHistogram("ConversationLength", "1d");
  const donutData = histogramToAggregatedData(chartData, keys);
  const orderedDonutData = [...donutData].sort(
    (a, b) => Number(a.name) - Number(b.name)
  );
  if (error) return AxiosErrorBox({ error });

  return (
    <Card>
      <CardHeader>
        <CardTitle className="text-lg  font-semibold">
          Conversation Length
        </CardTitle>
      </CardHeader>
      <CardContent>
        {isPending ? (
          <Skeleton className="h-40" />
        ) : (
          <DonutChart
            className="mx-auto"
            data={orderedDonutData}
            label={`Ø ${avg(aggregateHistogramData(chartData, keys)).toFixed(1)}`}
            showLabel={true}
            category="name"
            value="value"
            colorMap={
              new Map([
                ["1", "#4ADE80"], // green-400
                ["2", "#22C55E"], // green-500
                ["3", "#16A34A"], // green-600
                ["4", "#A3E635"], // lime-400
                ["5", "#84CC16"], // lime-500
                ["6", "#65A30D"], // lime-600
                ["7", "#FACC15"], // yellow-400
                ["8", "#EAB308"], // yellow-500
                ["9", "#CA8A04"], // yellow-600
                ["10", "#F87171"], // red-400
                ["11", "#EF4444"], // red-500
                ["12", "#DC2626"], // red-600
              ])
            }
            valueFormatter={(value) =>
              value == 1 ? `${value} message` : `${value} messages`
            }
          />
        )}
      </CardContent>
    </Card>
  );
}
