import { Fragment, useState } from "react";

import {
  Breadcrumb,
  BreadcrumbEllipsis,
  BreadcrumbItem,
  BreadcrumbLink,
  BreadcrumbList,
  BreadcrumbPage,
  BreadcrumbSeparator,
} from "@/components/ui/breadcrumb";
import { Button } from "@/components/ui/button";
import {
  Drawer,
  DrawerClose,
  DrawerContent,
  DrawerDescription,
  DrawerFooter,
  DrawerHeader,
  DrawerTitle,
  DrawerTrigger,
} from "@/components/ui/drawer";
import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuItem,
  DropdownMenuTrigger,
} from "@/components/ui/dropdown-menu";
import { SidebarTrigger } from "../ui/sidebar";
import { Separator } from "../ui/separator";
import { UserButton } from "@clerk/clerk-react";
import { useIsMobile } from "@/hooks/use-mobile";
import { Skeleton } from "../ui/skeleton";
import LinkWithQuery from "../LinkWithQuery";

interface BreadcrumbItem {
  href?: string;
  label: string;
}

interface BreadcrumbProps {
  items: BreadcrumbItem[];
}

export function BreadcrumbNavigation({ items }: BreadcrumbProps) {
  const [open, setOpen] = useState(false);
  const isMobile = useIsMobile();

  const ITEMS_TO_DISPLAY = isMobile ? 2 : 4;
  const ITEMS_FROM_END = Math.max(-ITEMS_TO_DISPLAY, -items.length) + 1;
  const ITEMS_FROM_MIDDLE = -(ITEMS_TO_DISPLAY - 1);

  const behindEllipse = items.slice(1).slice(ITEMS_FROM_END);
  const hiddenItems = items.slice(1, ITEMS_FROM_MIDDLE);

  return (
    <Breadcrumb>
      <BreadcrumbList>
        <BreadcrumbItem>
          {items[0].href ? (
            <BreadcrumbLink asChild className="max-w-20 truncate md:max-w-36">
              <LinkWithQuery to={items[0].href}>{items[0].label}</LinkWithQuery>
            </BreadcrumbLink>
          ) : (
            <BreadcrumbPage className="max-w-20 truncate md:max-w-36">
              {items[0].label}
            </BreadcrumbPage>
          )}
        </BreadcrumbItem>
        {items.length > 1 && <BreadcrumbSeparator />}
        {hiddenItems.length > 0 ? (
          <>
            <BreadcrumbItem>
              {isMobile ? (
                <Drawer open={open} onOpenChange={setOpen}>
                  <DrawerTrigger aria-label="Toggle Menu">
                    <BreadcrumbEllipsis className="h-4 w-4" />
                  </DrawerTrigger>
                  <DrawerContent>
                    <DrawerHeader className="text-left">
                      <DrawerTitle>Navigate to</DrawerTitle>
                      <DrawerDescription>
                        Select a page to navigate to.
                      </DrawerDescription>
                    </DrawerHeader>
                    <div className="grid gap-1 px-4">
                      {hiddenItems.map((item, index) => (
                        <LinkWithQuery
                          key={index}
                          to={item.href ? item.href : "#"}
                          className="py-1 text-sm"
                        >
                          {item.label}
                        </LinkWithQuery>
                      ))}
                    </div>
                    <DrawerFooter className="pt-4">
                      <DrawerClose asChild>
                        <Button variant="outline">Close</Button>
                      </DrawerClose>
                    </DrawerFooter>
                  </DrawerContent>
                </Drawer>
              ) : (
                <DropdownMenu open={open} onOpenChange={setOpen}>
                  <DropdownMenuTrigger
                    className="flex items-center gap-1"
                    aria-label="Toggle menu"
                  >
                    <BreadcrumbEllipsis className="h-4 w-4" />
                  </DropdownMenuTrigger>
                  <DropdownMenuContent align="start">
                    {hiddenItems.map((item, index) => (
                      <DropdownMenuItem
                        key={index}
                        className="max-w-20 truncate md:max-w-36"
                      >
                        <LinkWithQuery to={item.href ? item.href : "#"}>
                          {item.label}
                        </LinkWithQuery>
                      </DropdownMenuItem>
                    ))}
                  </DropdownMenuContent>
                </DropdownMenu>
              )}
            </BreadcrumbItem>
            <BreadcrumbSeparator />
          </>
        ) : null}
        {behindEllipse.map((item, index) => (
          <Fragment key={index}>
            <BreadcrumbItem>
              {item.href ? (
                <BreadcrumbLink
                  asChild
                  className="max-w-20 truncate md:max-w-36"
                >
                  <LinkWithQuery to={item.href}>{item.label}</LinkWithQuery>
                </BreadcrumbLink>
              ) : (
                <BreadcrumbPage className="max-w-20 truncate md:max-w-36">
                  {item.label}
                </BreadcrumbPage>
              )}
            </BreadcrumbItem>
            {index < behindEllipse.length - 1 && <BreadcrumbSeparator />}
          </Fragment>
        ))}
      </BreadcrumbList>
    </Breadcrumb>
  );
}

export default function NavigationHeader({ items }: BreadcrumbProps) {
  return (
    <header className="flex h-16 shrink-0 justify-between items-center gap-2 transition-[width,height] ease-linear group-has-[[data-collapsible=icon]]/sidebar-wrapper:h-12">
      <div className="flex items-center gap-1">
        <SidebarTrigger className="-ml-1 p-5" />
        <Separator orientation="vertical" className="mr-2 h-4" />
        <BreadcrumbNavigation items={items} />
      </div>
      <div className="flex items-center gap-2">
        <UserButton />
      </div>
    </header>
  );
}

export function NavigationHeaderSkeleton() {
  return (
    <header className="flex h-16 shrink-0 justify-between items-center gap-2 transition-[width,height] ease-linear group-has-[[data-collapsible=icon]]/sidebar-wrapper:h-12">
      <div className="flex items-center gap-1">
        <Skeleton className="h-7 w-7 -ml-1" />
        <Separator orientation="vertical" className="mr-2 h-4" />
        <div className="flex items-center space-x-1">
          <Skeleton className="h-4 w-24" />
          <Skeleton className="h-4 w-4" />
          <Skeleton className="h-4 w-24" />
          <Skeleton className="h-4 w-4" />
          <Skeleton className="h-4 w-24" />
        </div>
      </div>

      <div className="flex items-center gap-2">
        <Skeleton className="h-9 w-9 rounded-full" />
      </div>
    </header>
  );
}
