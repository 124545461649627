const intervalRegex =
  /^((?<days>[\d.]+?)d)?((?<hours>[\d.]+?)h)?((?<minutes>[\d.]+?)m)?$/;

interface TimeComponents {
  days?: number;
  hours?: number;
  minutes?: number;
}

export function parseInterval(interval: string): number {
  const match = intervalRegex.exec(interval);

  if (!match?.groups) {
    throw new Error(
      `Could not parse time interval from '${interval}'. Examples of valid formats: '8h', '2d8h5m', '15m'`
    );
  }

  const components: TimeComponents = {};

  // Extract and convert matched groups to numbers
  for (const [key, value] of Object.entries(match.groups)) {
    if (value !== undefined) {
      components[key as keyof TimeComponents] = parseFloat(value);
    }
  }

  const milliseconds =
    (components.days || 0) * 24 * 60 * 60 * 1000 +
    (components.hours || 0) * 60 * 60 * 1000 +
    (components.minutes || 0) * 60 * 1000;

  return milliseconds;
}

export function addInterval(date: Date, interval: string): Date {
  const milliseconds = parseInterval(interval);
  return new Date(date.getTime() + milliseconds);
}

export function subInterval(date: Date, interval: string): Date {
  const milliseconds = parseInterval(interval);
  return new Date(date.getTime() - milliseconds);
}

// WARNING(memben): coupled to backend EPOCH_REFERENCE logic
const EPOCH_REFERENCE = new Date(Date.UTC(1990, 0, 1, 0, 0, 0));

export function generateDateRange(
  since: Date,
  until: Date,
  interval: string
): string[] {
  const dates: string[] = [];
  let curr = new Date(EPOCH_REFERENCE);

  while (curr <= until) {
    if (curr >= subInterval(since, interval)) {
      dates.push(curr.toISOString());
    }
    curr = addInterval(curr, interval);
  }

  return dates;
}

export type HistogramData = [string, string, number]; // [date, key, count]

interface TransformedHistogramData {
  data: Record<string, any>[];
  keys: string[];
}

export function transformHistogramData(
  data: HistogramData[],
  dates: string[]
): TransformedHistogramData {
  // Validate all dates are within range
  const validDates = new Set(dates);
  data.forEach(([date]) => {
    const jsDate = new Date(date).toISOString();
    if (!validDates.has(jsDate)) {
      throw new Error(
        `Date ${jsDate} from metric data is not present in the generated date range`
      );
    }
  });

  // Get unique keys
  const uniqueKeys = [...new Set(data.map(([_, key]) => key))];

  // Transform data into object with date keys for O(1) lookup
  const dateMap: Record<string, Record<string, number>> = {};
  data.forEach(([date, key, count]) => {
    const jsDate = new Date(date).toISOString();
    if (!dateMap[jsDate]) {
      dateMap[jsDate] = {};
    }
    dateMap[jsDate][key] = (dateMap[jsDate][key] || 0) + count;
  });

  // Create final array with zero-filling
  const transformedData = dates.map((date) => {
    const point: Record<string, any> = { date };
    uniqueKeys.forEach((key) => {
      point[key] = dateMap[date]?.[key] || 0;
    });
    return point;
  });

  return {
    data: transformedData,
    keys: uniqueKeys,
  };
}

export function histogramToAggregatedData(
  data: Record<string, any>[],
  keys: string[]
) {
  const aggregate = aggregateHistogramData(data, keys);
  const total = keys.reduce((acc, key) => acc + aggregate[key], 0);
  return keys.map((key) => ({
    name: key,
    value: aggregate[key],
    percent: (aggregate[key] / total) * 100,
  }));
}

export function aggregateHistogramData(
  data: Record<string, any>[],
  keys: string[]
) {
  const aggregated: Record<string, number> = {};
  data.forEach((point) => {
    keys.forEach((key) => {
      aggregated[key] = (aggregated[key] || 0) + point[key];
    });
  });
  return aggregated;
}
