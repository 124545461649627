import React from "react";
import { useQuery, useMutation, useQueryClient } from "@tanstack/react-query";
import { Link, useParams } from "react-router-dom";
import {
  Card,
  CardHeader,
  CardTitle,
  CardDescription,
} from "@/components/ui/card";
import { Button } from "@/components/ui/button";
import { Input } from "@/components/ui/input";
import { Label } from "@/components/ui/label";
import {
  Dialog,
  DialogContent,
  DialogDescription,
  DialogFooter,
  DialogHeader,
  DialogTitle,
  DialogTrigger,
} from "@/components/ui/dialog";
import { Plus } from "lucide-react";
import { toast } from "sonner";
import {
  createTableMutation,
  listTablesOptions,
} from "@/client/@tanstack/react-query.gen";
import { Feature } from "@/features";
import PageHeader from "../PageHeader";
import NavigationHeader from "../sidebar/NavigationHeader";
import { useCurrentProjectBreadcrumbs } from "@/hooks";

// Tables List Component
export const TablesList = () => {
  const { projectId } = useParams();
  const queryClient = useQueryClient();

  // Use proper listTablesOptions with path parameters
  const { data: tables, isLoading } = useQuery({
    ...listTablesOptions({
      path: { project_id: Number(projectId) },
    }),
  });

  // Use createTableMutation with proper options
  const createTable = useMutation({
    ...createTableMutation(),
    onSuccess: () => {
      const queryKey = listTablesOptions({
        path: { project_id: Number(projectId) },
      }).queryKey;
      queryClient.invalidateQueries({ queryKey });
      toast.success("Table created successfully");
      setIsCreateDialogOpen(false);
      setNewTableName("");
    },
    onError: (error) => {
      toast.error("Failed to create table: " + error.message);
    },
  });

  const [isCreateDialogOpen, setIsCreateDialogOpen] = React.useState(false);
  const [newTableName, setNewTableName] = React.useState("");

  const handleCreateTable = () => {
    createTable.mutate({
      path: {
        project_id: Number(projectId),
      },
      body: {
        config: {
          name: newTableName,
          fields: [],
          objects: [],
        },
      },
    });
  };

  const breadcrumbs = [...useCurrentProjectBreadcrumbs(), { label: "Tables" }];

  if (isLoading) {
    return <div className="p-4">Loading tables...</div>;
  }
  return (
    <Feature flag="search-tables">
      <NavigationHeader items={breadcrumbs} />
      <div className="p-4">
        <PageHeader title="Tables" subtitle="Manage your tables">
          {/* <div className="w-full h-[calc(100vh-150px)]">
       
      </div> */}

          <div className="flex justify-between items-center">
            <Dialog
              open={isCreateDialogOpen}
              onOpenChange={setIsCreateDialogOpen}
            >
              <DialogTrigger asChild>
                <Button>
                  <Plus className="w-4 h-4 mr-2" />
                  Create Table
                </Button>
              </DialogTrigger>
              <DialogContent>
                <DialogHeader>
                  <DialogTitle>Create New Table</DialogTitle>
                  <DialogDescription>
                    Enter the details for your new table.
                  </DialogDescription>
                </DialogHeader>
                <div className="space-y-4 py-4">
                  <div className="space-y-2">
                    <Label htmlFor="name">Name</Label>
                    <Input
                      id="name"
                      value={newTableName}
                      onChange={(e) => setNewTableName(e.target.value)}
                      placeholder="Enter table name"
                    />
                  </div>
                </div>
                <DialogFooter>
                  <Button
                    variant="outline"
                    onClick={() => setIsCreateDialogOpen(false)}
                  >
                    Cancel
                  </Button>
                  <Button
                    onClick={handleCreateTable}
                    disabled={!newTableName || createTable.isPending}
                  >
                    {createTable.isPending ? "Creating..." : "Create"}
                  </Button>
                </DialogFooter>
              </DialogContent>
            </Dialog>
          </div>
        </PageHeader>
        <div className="grid gap-4 md:grid-cols-2 lg:grid-cols-3 mt-4">
          {tables?.data?.map((table) => (
            <Card key={table.id} className="hover:shadow-lg transition-shadow">
              <CardHeader>
                <Link to={`/${projectId}/tables/${table.id}`}>
                  <CardTitle className="text-lg hover:text-blue-600">
                    {table.name}
                  </CardTitle>
                  <CardDescription className="text-sm text-gray-500">
                    ID: {table.id}
                  </CardDescription>
                </Link>
              </CardHeader>
            </Card>
          ))}
        </div>
      </div>
    </Feature>
  );
};

export default TablesList;
