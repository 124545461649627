import { Badge } from "@/components/ui/badge";
import { TrendingUp, TrendingDown } from "lucide-react";
import { Topic } from "@/client";
import { subDays } from "date-fns";

// Helper function to calculate trend
export function calculateTrend(data: Record<string, any>[], topic: Topic) {
  const isNew = topic.created_at > subDays(new Date(), 7);
  if (isNew) {
    return { type: "new" };
  }

  // Sort data by date in descending order to get latest periods
  const sortedData = [...data].sort(
    (a, b) => new Date(b.date).getTime() - new Date(a.date).getTime()
  );

  // WRONG/TODO(memben): when the until is not now() this should be 0, 1
  const lastFullInterval =
    sortedData[sortedData.length > 2 ? 1 : 0][topic.title];
  const penultimateFullInterval =
    sortedData[sortedData.length > 2 ? 2 : 1][topic.title];

  if (lastFullInterval === 0 && penultimateFullInterval === 0) {
    return { type: "inactive" };
  }

  if (penultimateFullInterval === 0) {
    return { type: "up", value: 100 };
  }

  const growthPercent =
    ((lastFullInterval - penultimateFullInterval) / penultimateFullInterval) *
    100;

  if (Math.abs(growthPercent) <= 5) {
    return {
      type: "stable",
      avg: Math.round((lastFullInterval + penultimateFullInterval) / 2),
    };
  }

  return {
    type: growthPercent > 0 ? "up" : "down",
    value: Math.abs(Math.round(growthPercent)),
  };
}

const TrendBadge = ({
  data,
  topic,
}: {
  data: Record<string, any>[];
  topic: Topic;
}) => {
  const trend = calculateTrend(data, topic);

  switch (trend.type) {
    case "new":
      return (
        <Badge
          variant="secondary"
          className="w-fit bg-gradient-to-r from-blue-500 to-purple-500 text-white hover:from-blue-600 hover:to-purple-600 whitespace-nowrap"
        >
          ✨ New
        </Badge>
      );
    case "up":
      return (
        <Badge
          variant="secondary"
          className="w-fit flex items-center gap-1 bg-gradient-to-r from-green-500 to-emerald-500 text-white hover:from-green-600 hover:to-emerald-600 whitespace-nowrap"
        >
          <TrendingUp className="h-3 w-3" />+{trend.value}% Conversations
        </Badge>
      );
    case "down":
      return (
        <Badge
          variant="destructive"
          className="w-fit flex items-center gap-1 bg-gradient-to-r from-red-500 to-rose-500 text-white hover:from-red-600 hover:to-rose-600 whitespace-nowrap"
        >
          <TrendingDown className="h-3 w-3" />-{trend.value}% Conversations
        </Badge>
      );
    case "inactive":
      return (
        <Badge
          variant="secondary"
          className="w-fit bg-gradient-to-r from-gray-500 to-gray-600 text-white hover:from-gray-600 hover:to-gray-700 whitespace-nowrap"
        >
          No Recent Mentions
        </Badge>
      );
    case "stable":
      return (
        <Badge variant="outline" className="w-fit whitespace-nowrap">
          Stable Ø {trend.avg} Conversations
        </Badge>
      );
    default:
      return null;
  }
};

export default TrendBadge;
