import { startOfDay, subDays, differenceInDays } from "date-fns";

import { useMetric } from "../hooks";

export const useMetricComparison = (
  metricName: string,
  valueKey: string,
  since: string,
  until: string
) => {
  const current = {
    metricName,
    interval: "1d",
    since: since,
    until: until,
  };

  const days = differenceInDays(new Date(until), new Date(since));

  const previous = {
    metricName,
    interval: "1d",
    since: startOfDay(subDays(since, days)).toISOString(),
    until: startOfDay(since).toISOString(),
  };

  const { metric: currentMetric, status: currentStatus } = useMetric(current);
  const { metric: previousMetric, status: previousStatus } =
    useMetric(previous);

  if (currentStatus !== "succeeded" || previousStatus !== "succeeded") {
    return { current: 0, previous: 0, trend: 0, status: "loading" };
  }

  // Special handling for UniquePageVisitorCount which has a different data structure
  if (metricName === "UniquePageVisitorCount") {
    const currentValue: number = currentMetric.result.values.reduce(
      (acc: number, v: number) => acc + v,
      0
    );
    const previousValue: number = previousMetric.result.values.reduce(
      (acc: number, v: number) => acc + v,
      0
    );

    const trend =
      previousValue === 0
        ? currentValue === 0
          ? 0
          : 100
        : ((currentValue - previousValue) / previousValue) * 100;

    return {
      current: currentValue,
      previous: previousValue,
      trend,
      status: "succeeded",
    };
  }

  // Standard handling for other metrics
  const currentRows = getRowsFromMetric(currentMetric);
  const previousRows = getRowsFromMetric(previousMetric);

  const currentValue = currentRows.reduce(
    (sum: number, row: Record<string, number | string>) =>
      sum + ((row[valueKey] as number) || 0),
    0
  );
  const previousValue = previousRows.reduce(
    (sum: number, row: Record<string, number | string>) =>
      sum + ((row[valueKey] as number) || 0),
    0
  );

  const trend =
    previousValue === 0
      ? currentValue === 0
        ? 0
        : 100
      : ((currentValue - previousValue) / previousValue) * 100;

  return {
    current: currentValue,
    previous: previousValue,
    trend,
    status: "succeeded",
  };
};

// Helper functions
export const getRowsFromMetric = (metric: any) => {
  return metric.data.map(
    (row: string | number[]) =>
      metric.columns.reduce((o: any, k: string, i: number) => {
        return { ...o, [k]: row[i] };
      }, {}) as Record<string, number | string>
  );
};
