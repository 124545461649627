import { ChevronsUpDown, Plus, Check, Home } from "lucide-react";

import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuItem,
  DropdownMenuLabel,
  DropdownMenuSeparator,
  DropdownMenuTrigger,
} from "@/components/ui/dropdown-menu";
import { ScrollArea } from "@/components/ui/scroll-area";
import {
  SidebarMenu,
  SidebarMenuButton,
  SidebarMenuItem,
  useSidebar,
} from "@/components/ui/sidebar";
import { useProjectsV2 } from "@/hooks";
import { useNavigate, useParams } from "react-router-dom";

export function ProjectSwitcher() {
  const { isMobile } = useSidebar();
  const { projectId } = useParams();
  const navigate = useNavigate();
  const { data: projectsList, isPending, error } = useProjectsV2();

  if (isPending) {
    return null;
  }

  if (error) {
    console.error(error);
    return null;
  }

  const projects = projectsList.projects.sort((a, b) => b.id - a.id);

  const activeProject = projects.find(
    (project) => project.id === Number(projectId)
  );

  const getFavicon = (url: string | null | undefined) => {
    return url
      ? `https://www.google.com/s2/favicons?domain=${url}&sz=32`
      : undefined;
  };

  return (
    <SidebarMenu>
      <SidebarMenuItem>
        {/* TODO(memben): known issue, scrolling does not work on mobile https://github.com/shadcn-ui/ui/issues/149 */}
        <DropdownMenu>
          <DropdownMenuTrigger asChild>
            <SidebarMenuButton
              size="lg"
              className="data-[state=open]:bg-sidebar-accent data-[state=open]:text-sidebar-accent-foreground"
            >
              <div
                className={`flex aspect-square size-8 items-center justify-center rounded-lg ${activeProject?.website_url ? "border-2" : "bg-sidebar-primary text-sidebar-primary-foreground"}`}
              >
                {activeProject?.website_url ? (
                  <img
                    src={getFavicon(activeProject.website_url)}
                    alt={`${activeProject.name} favicon`}
                    className="size-6 rounded-sm"
                    onError={(e) => {
                      e.currentTarget.src = "";
                      e.currentTarget.className = "hidden";
                    }}
                  />
                ) : (
                  <Home className="size-4" />
                )}
              </div>
              <div className="grid flex-1 min-w-0 text-left text-sm leading-tight">
                <span className="truncate font-semibold">
                  {activeProject?.name || "Select Project"}
                </span>
                {activeProject?.description && (
                  <span className="truncate text-xs text-muted-foreground">
                    {activeProject?.description}
                  </span>
                )}
              </div>
              <ChevronsUpDown className="ml-auto flex-shrink-0" />
            </SidebarMenuButton>
          </DropdownMenuTrigger>
          <DropdownMenuContent
            className="w-[--radix-dropdown-menu-trigger-width] min-w-56 rounded-lg"
            align="start"
            side={isMobile ? "bottom" : "right"}
            sideOffset={4}
          >
            <DropdownMenuLabel className="text-xs text-muted-foreground">
              Projects
            </DropdownMenuLabel>
            <ScrollArea className="h-[400px] px-1">
              <div className="space-y-1">
                {projects.map((project) => (
                  <DropdownMenuItem
                    key={project.id}
                    onClick={() => navigate(`/${project.id}`)}
                    className="gap-2 p-2"
                  >
                    <div className="flex size-6 items-center justify-center rounded-sm flex-shrink-0 ">
                      {project.website_url ? (
                        <img
                          src={getFavicon(project.website_url)}
                          alt={`${project.name} favicon`}
                          className="size-6 rounded-sm flex-shrink-0"
                          onError={(e) => {
                            e.currentTarget.src = "";
                            e.currentTarget.className = "hidden";
                          }}
                        />
                      ) : (
                        <Home className="size-4" />
                      )}
                    </div>
                    <div className="flex flex-1 min-w-0 items-center justify-between">
                      <div className="min-w-0 flex-1">
                        <div className="truncate font-medium">
                          {project.name}
                        </div>
                        {project.description && (
                          <div className="truncate text-xs text-muted-foreground">
                            {project.description}
                          </div>
                        )}
                      </div>
                      {project.id === Number(projectId) && (
                        <Check className="ml-2 size-4 text-primary flex-shrink-0" />
                      )}
                    </div>
                  </DropdownMenuItem>
                ))}
              </div>
            </ScrollArea>
            <DropdownMenuSeparator />
            <DropdownMenuItem
              className="gap-2 p-2"
              onClick={() => {
                window.$crisp.push(["do", "chat:show"]);
                window.$crisp.push(["do", "chat:open"]);
              }}
            >
              <div className="flex size-6 items-center justify-center rounded-md border bg-background flex-shrink-0">
                <Plus className="size-4" />
              </div>
              <div className="font-medium text-muted-foreground truncate">
                Add project
              </div>
            </DropdownMenuItem>
          </DropdownMenuContent>
        </DropdownMenu>
      </SidebarMenuItem>
    </SidebarMenu>
  );
}

export default ProjectSwitcher;
