/**
 * A textarea with JSON validation and visual feedback. Calls change handler only when valid JSON is entered.
 *
 * @component
 * @param {Object} props - The component props
 * @param {string} props.value - The JSON string value to be displayed in the textarea
 * @param {function} props.onChange - Callback function triggered when valid JSON is entered
 * @param {string} [props.className] - Additional CSS classes to apply to the textarea
 * @param {string} [props.placeholder="Enter JSON..."] - Placeholder text for the textarea
 * @param {boolean} [props.disabled=false] - Whether the textarea is disabled
 *
 * @returns {JSX.Element} A textarea with JSON validation and visual feedback
 *
 * @example
 * <JsonTextarea
 *   value={jsonString}
 *   onChange={(e) => setJsonString(e.target.value)}
 *   className="min-h-[200px]"
 *   placeholder="Enter your JSON here"
 * />
 */
import { useState, useEffect, ChangeEvent } from "react";
import { Textarea } from "@/components/ui/textarea";
import { AlertCircle, Check } from "lucide-react";

const JsonTextarea = ({
  value,
  onChange,
  className = "",
  placeholder = "Enter JSON...",
  disabled = false,
}: {
  value: string;
  onChange: (e: ChangeEvent<HTMLTextAreaElement>) => void;
  className?: string;
  placeholder?: string;
  disabled?: boolean;
}) => {
  const [internalValue, setInternalValue] = useState(value);

  useEffect(() => {
    setInternalValue(value);
  }, [value]);

  useEffect(() => {
    setInternalValue(value);
  }, [value]);

  const validateJson = (text: string) => {
    try {
      JSON.parse(text);
      return true;
    } catch (e) {
      return false;
    }
  };
  const isValid = validateJson(internalValue);

  const handleChange = (e: ChangeEvent<HTMLTextAreaElement>) => {
    const newValue = e.target.value;
    setInternalValue(newValue);
    if (validateJson(newValue)) {
      onChange(e);
    }
  };

  return (
    <div className="relative">
      <Textarea
        value={internalValue}
        onChange={handleChange}
        className={`${className} ${!isValid ? "border-red-500 focus-visible:ring-red-500" : "border-green-500 focus-visible:ring-green-500"}`}
        placeholder={placeholder}
        disabled={disabled}
      />
      <div className="absolute right-2 top-2">
        {isValid ? (
          <Check className="h-5 w-5 text-green-500" />
        ) : (
          <AlertCircle className="h-5 w-5 text-red-500" />
        )}
      </div>
    </div>
  );
};

export default JsonTextarea;
