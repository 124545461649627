import { ArrowRight } from "lucide-react";
import { Card, CardContent } from "../ui/card";
import { Topic } from "@/client";
import { createTopicColorMap } from "@/colors";
import { useQuery } from "@tanstack/react-query";
import { listTopicsOptions } from "@/client/@tanstack/react-query.gen";
import { useHistogram } from "../charts/hooks";
import { aggregateHistogramData } from "../charts/helper";
import { AxiosErrorBox } from "../Error";
import { useParams } from "react-router-dom";
import LinkWithQuery from "../LinkWithQuery";
import Spinner from "../Loading";
import TrendBadge from "./TrendBadge";

const TopicCard = ({
  topic,
  color,
  conversations,
  topicHistogram,
}: {
  topic: Topic;
  color: string;
  topicHistogram: Record<string, any>[];
  conversations: number;
}) => {
  return (
    <LinkWithQuery to={`/${topic.project_id}/conversations?topic=${topic.id}`}>
      <Card className="cursor-pointer hover:bg-gray-50 transition-colors duration-200 h-full">
        <CardContent className="p-4 flex flex-col h-full">
          <div className="space-y-2 flex-grow">
            <div className="flex gap-2">
              <svg
                width="12"
                height="12"
                viewBox="0 0 12 12"
                className="mt-1.5 ml-1"
              >
                <circle cx="6" cy="6" r="6" fill={color} />
              </svg>

              <h3 className="font-semibold flex items-center">{topic.title}</h3>
            </div>
            <TrendBadge data={topicHistogram} topic={topic} />
            <p className="text-sm text-gray-600">{topic.description}</p>
          </div>

          <div className="flex justify-between items-center space-x-2 mt-2">
            <span className="text-sm font-medium text-gray-600">
              {conversations.toLocaleString()} conversations
            </span>
            <ArrowRight className="h-4 w-4 text-gray-500" />
          </div>
        </CardContent>
      </Card>
    </LinkWithQuery>
  );
};

export default function TopicCards({
  groupingInterval,
}: {
  groupingInterval: string;
}) {
  const { projectId } = useParams();
  const {
    data: topicHistogram,
    keys: topicNames,
    isPending,
    error,
  } = useHistogram("ConversationTopicV2", groupingInterval);

  console.log(topicHistogram);

  const aggregate = aggregateHistogramData(topicHistogram, topicNames);

  const { data } = useQuery({
    ...listTopicsOptions({
      path: {
        project_id: Number(projectId),
      },
    }),
  });
  const topics = data?.data || [];
  const getTopicColor = createTopicColorMap(topics.map((topic) => topic.id));

  if (error) return <AxiosErrorBox error={error} />;

  if (isPending) {
    return <Spinner />;
  }

  return (
    <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-4">
      {topics.map((topic) => (
        <TopicCard
          key={topic.id}
          topic={topic}
          color={getTopicColor(topic.id).main}
          conversations={aggregate[topic.title] || 0}
          topicHistogram={topicHistogram}
        />
      ))}
    </div>
  );
}
