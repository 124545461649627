// import { WidgetProps } from "@rjsf/utils";
// import { useCallback, useMemo, FocusEvent } from "react";

// const CheckboxSelectWidget = ({
//   id,
//   disabled,
//   options,
//   value,
//   required,
//   readonly,
//   onChange,
//   onBlur,
//   onFocus,
//   multiple = false,
// }: WidgetProps) => {
//   const { enumOptions = [] } = options;

//   // Ensure value is properly typed and initialized
//   const selectedValues = useMemo(() => {
//     if (multiple) {
//       return Array.isArray(value) ? value : value != null ? [value] : [];
//     }
//     // For single select, always maintain as single value
//     return value != null ? [value] : [];
//   }, [value, multiple]);

//   // Handler for checkbox changes
//   const handleChange = useCallback(
//     (optionValue: any) => {
//       if (multiple) {
//         // Handle multi-select case
//         const newValues = selectedValues.includes(optionValue)
//           ? selectedValues.filter((v) => v !== optionValue)
//           : [...selectedValues, optionValue];

//         // Sort to maintain consistent order
//         newValues.sort((a, b) => String(a).localeCompare(String(b)));
//         onChange(newValues);
//       } else {
//         // Handle single-select case
//         // If clicking the already selected item, deselect it
//         const newValue = selectedValues.includes(optionValue) ? undefined : optionValue;
//         onChange(newValue);
//       }
//     },
//     [multiple, onChange, selectedValues]
//   );

//   // Proper event handlers with correct types
//   const handleBlur = useCallback(
//     (event: FocusEvent<HTMLDivElement>) => {
//       if (onBlur) {
//         onBlur(id, value);
//       }
//     },
//     [id, onBlur, value]
//   );

//   const handleFocus = useCallback(
//     (event: FocusEvent<HTMLDivElement>) => {
//       if (onFocus) {
//         onFocus(id, value);
//       }
//     },
//     [id, onFocus, value]
//   );

//   return (
//     <div
//       className="checkbox-select-widget"
//       onBlur={handleBlur}
//       onFocus={handleFocus}
//       tabIndex={0}
//       role="group"
//       aria-labelledby={`${id}-label`}
//     >
//       {/* Hidden select for form validation */}
//       <select
//         id={id}
//         name={id}
//         multiple={multiple}
//         value={selectedValues}
//         required={required}
//         disabled={disabled || readonly}
//         onChange={() => {}}
//         style={{ display: "none" }}
//         aria-hidden="true"
//       >
//         {enumOptions.map(({ value: optionValue }) => (
//           <option key={String(optionValue)} value={optionValue} />
//         ))}
//       </select>

//       {/* Visible checkboxes */}
//       <div className="border rounded-md shadow-sm bg-white">
//         {enumOptions.map(({ value: optionValue, label }, index) => {
//           const isChecked = selectedValues.includes(optionValue);
//           const isLastItem = index === enumOptions.length - 1;

//           return (
//             <label
//               key={String(optionValue)}
//               className={`
//                 flex items-center px-4 py-2
//                 ${disabled || readonly ? "opacity-50 cursor-not-allowed" : "hover:bg-gray-50 cursor-pointer"}
//                 ${!isLastItem ? "border-b" : ""}
//               `}
//             >
//               <input
//                 type="checkbox"
//                 className="h-4 w-4 text-blue-600 rounded border-gray-300 focus:ring-blue-500 mr-3"
//                 checked={isChecked}
//                 onChange={() => handleChange(optionValue)}
//                 disabled={disabled || readonly}
//                 aria-label={String(label || optionValue)}
//               />
//               <span className="text-gray-700">{label || optionValue}</span>
//             </label>
//           );
//         })}
//       </div>
//     </div>
//   );
// };

// export default CheckboxSelectWidget;
import { WidgetProps } from "@rjsf/utils";
import { useCallback, useMemo, FocusEvent } from "react";

const CheckboxSelectWidget = ({
  id,
  disabled,
  options,
  value,
  required,
  readonly,
  onChange,
  onBlur,
  onFocus,
  multiple = false,
}: WidgetProps) => {
  const { enumOptions = [] } = options;

  // Ensure value is properly typed and initialized
  const selectedValues = useMemo(() => {
    if (multiple) {
      return Array.isArray(value) ? value : value !== undefined ? [value] : [];
    }
    // For single select, always maintain as single value
    return value !== undefined ? [value] : [];
  }, [value, multiple]);

  // Handler for checkbox changes
  const handleChange = useCallback(
    (optionValue: any) => {
      if (multiple) {
        // Handle multi-select case
        const newValues = selectedValues.some(
          (v) =>
            // Handle null comparison explicitly
            (v === null && optionValue === null) || v === optionValue
        )
          ? selectedValues.filter(
              (v) =>
                // Handle null comparison in filter
                !(v === null && optionValue === null) && v !== optionValue
            )
          : [...selectedValues, optionValue];

        // Sort to maintain consistent order, handling null values
        newValues.sort((a, b) => {
          if (a === null) return -1;
          if (b === null) return 1;
          return String(a).localeCompare(String(b));
        });

        onChange(newValues);
      } else {
        // Handle single-select case
        // If clicking the already selected item, deselect it
        const isCurrentlySelected = selectedValues.some(
          (v) => (v === null && optionValue === null) || v === optionValue
        );
        const newValue = isCurrentlySelected ? undefined : optionValue;
        onChange(newValue);
      }
    },
    [multiple, onChange, selectedValues]
  );

  // Proper event handlers with correct types
  const handleBlur = useCallback(
    (_: FocusEvent<HTMLDivElement>) => {
      if (onBlur) {
        onBlur(id, value);
      }
    },
    [id, onBlur, value]
  );

  const handleFocus = useCallback(
    (_: FocusEvent<HTMLDivElement>) => {
      if (onFocus) {
        onFocus(id, value);
      }
    },
    [id, onFocus, value]
  );

  // Helper function to check if a value is selected
  const isValueSelected = (optionValue: any) => {
    return selectedValues.some(
      (v) => (v === null && optionValue === null) || v === optionValue
    );
  };

  return (
    <div
      className="checkbox-select-widget"
      onBlur={handleBlur}
      onFocus={handleFocus}
      tabIndex={0}
      role="group"
      aria-labelledby={`${id}-label`}
    >
      {/* Hidden select for form validation */}
      <select
        id={id}
        name={id}
        multiple={multiple}
        value={selectedValues}
        required={required}
        disabled={disabled || readonly}
        onChange={() => {}}
        style={{ display: "none" }}
        aria-hidden="true"
      >
        {enumOptions.map(({ value: optionValue }) => (
          <option
            key={String(optionValue ?? "null")}
            value={optionValue ?? ""}
          />
        ))}
      </select>

      {/* Visible checkboxes */}
      <div className="border rounded-md shadow-sm bg-white">
        {enumOptions.map(({ value: optionValue, label }, index) => {
          const isChecked = isValueSelected(optionValue);
          const isLastItem = index === enumOptions.length - 1;

          return (
            <label
              key={String(optionValue ?? "null")}
              className={`
                flex items-center px-4 py-2
                ${disabled || readonly ? "opacity-50 cursor-not-allowed" : "hover:bg-gray-50 cursor-pointer"}
                ${!isLastItem ? "border-b" : ""}
              `}
            >
              <input
                type="checkbox"
                className="h-4 w-4 text-blue-600 rounded border-gray-300 focus:ring-blue-500 mr-3"
                checked={isChecked}
                onChange={() => handleChange(optionValue)}
                disabled={disabled || readonly}
                aria-label={String((label || optionValue) ?? "null")}
              />
              <span className="text-gray-700">
                {label || (optionValue === null ? "null" : optionValue)}
              </span>
            </label>
          );
        })}
      </div>
    </div>
  );
};

export default CheckboxSelectWidget;
