type JsonPrimitive = string | number | boolean | null;
type JsonArray = JsonValue[];
type JsonObject = { [key: string]: JsonValue };
export type JsonValue = JsonPrimitive | JsonObject | JsonArray;

/**
 * Stringifies an object with all its properties (including nested) sorted by key.
 * @param value - The value to stringify
 * @param space - Number of spaces for indentation or string to use as indentation
 * @returns A JSON string with sorted keys
 */
const sortedStringify = (value: JsonValue, space?: number | string): string => {
  // Type guard for plain objects
  const isPlainObject = (value: JsonValue): value is JsonObject => {
    return (
      value !== null &&
      typeof value === "object" &&
      !Array.isArray(value) &&
      Object.getPrototypeOf(value) === Object.prototype
    );
  };

  const replacer = (_key: string, value: JsonValue): JsonValue => {
    if (isPlainObject(value)) {
      return Object.keys(value)
        .sort()
        .reverse()
        .reduce<JsonObject>((sorted, key) => {
          sorted[key] = value[key];
          return sorted;
        }, {});
    }
    return value;
  };

  return JSON.stringify(value, replacer, space);
};
export default sortedStringify;
