import { useParams } from "react-router-dom";
import { useQuery, useMutation } from "@tanstack/react-query";
import Form from "@rjsf/core";
import validator from "@rjsf/validator-ajv8";
import {
  Dialog,
  DialogContent,
  DialogHeader,
  DialogTitle,
  DialogTrigger,
} from "@/components/ui/dialog";
import { Button } from "@/components/ui/button";
import { Search as SearchIcon, Loader2 } from "lucide-react";
import {
  getSearchOptionsOptions,
  searchTableMutation,
} from "@/client/@tanstack/react-query.gen";
import { useState } from "react";
import widgets from "@/components/tables/Widgets";
import sortedStringify, { JsonValue } from "@/lib/sortedStringify";
import { toast } from "sonner";

const SearchDialog = () => {
  const { projectId, tableId } = useParams();
  const [searchResults, setSearchResults] = useState<any>(null);
  const [formData, setFormData] = useState<any>({});

  // Get search options
  const {
    data: searchOptions,
    isLoading: isLoadingOptions,
    error: optionsError,
  } = useQuery({
    ...getSearchOptionsOptions({
      path: {
        project_id: Number(projectId),
        table_id: tableId!,
      },
    }),
  });

  // Search mutation
  const { mutate: search, isPending: isSearching } = useMutation({
    ...searchTableMutation(),
    onSuccess: (data) => {
      setSearchResults(data);
    },
    onError: (error) =>
      toast.error(
        `Error searching: ${error.message} ${JSON.stringify(error.response?.data)}`
      ),
  });

  const handleSubmit = (data: any) => {
    setFormData(data.formData);
    search({
      path: {
        project_id: Number(projectId),
        table_id: tableId!,
      },
      body: data.formData,
    });
  };

  const handleChange = (data: any) => {
    console.log(data);
    // NOTE(liamvdv): empty strings will be discarded from the formData by the form library, thus force
    setFormData(data.formData);
  };
  console.log(formData, "formData");

  const renderContent = () => {
    if (isLoadingOptions) {
      return (
        <div className="flex items-center justify-center py-8">
          <Loader2 className="h-8 w-8 animate-spin text-muted-foreground" />
        </div>
      );
    }

    if (optionsError) {
      return (
        <div className="py-8 text-center text-destructive">
          Error loading search options: {optionsError.message}
        </div>
      );
    }

    const visitor = (obj: any) => {
      // Make Mulit-Select Field
      if (
        obj.type === "array" &&
        obj.items.type === "string" &&
        obj.items.enum
      ) {
        obj.uniqueItems = true;
      }
      return obj;
    };
    function recursive(obj: any) {
      if (obj.type === "object") {
        Object.keys(obj.properties).forEach((key) => {
          obj.properties[key] = visitor(obj.properties[key]);
          obj.properties[key] = recursive(obj.properties[key]);
        });
      }
      return obj;
    }
    const schema = recursive(
      JSON.parse(
        JSON.stringify(
          searchOptions?.parameters || {
            type: "object",
            properties: {},
          }
        )
      )
    );
    console.log("schema", schema);
    return (
      <div className="space-y-4 overflow-y-scroll">
        <div className="grid grid-cols-2 gap-4">
          {/* Left side - Search form and results */}
          <div className="bg-slate-50 p-4 rounded-lg max-h-[85vh] overflow-x-auto">
            <div className="flex flex-row justify-between ">
              <h3 className="font-medium mb-2">Search Schema</h3>
              <Button
                variant="outline"
                onClick={() => {
                  navigator.clipboard.writeText(
                    sortedStringify(searchOptions as JsonValue, 2)
                  );
                  const a = document.createElement("a");
                  a.href =
                    "https://platform.openai.com/playground/chat?models=gpt-4o";
                  a.target = "_blank"; // Opens in a new tab
                  a.click();
                }}
              >
                Copy & Test in Playground
              </Button>
            </div>

            <pre className="text-sm">
              {sortedStringify(searchOptions as JsonValue, 2)}
            </pre>
          </div>
          <div>
            {/* Right side - Search options data */}
            <div className="bg-slate-50 p-4 rounded-lg">
              <h3 className="font-medium mb-2">Input</h3>
              <Form
                schema={schema}
                validator={validator}
                onSubmit={handleSubmit}
                onChange={handleChange}
                formData={formData}
                disabled={isSearching}
                noHtml5Validate={true} // allow empty string ($.query)
                widgets={widgets}
              >
                <Button type="submit" disabled={isSearching} className="mt-4">
                  Search
                </Button>
              </Form>
            </div>
            <div className="bg-slate-50 p-4 rounded-lg mt-4">
              <h3 className="font-medium mb-2">Query</h3>
              <pre className="text-sm">{sortedStringify(formData, 2)}</pre>
            </div>
          </div>
        </div>
        <div className="bg-slate-50 p-4 rounded-lg">
          <h3 className="font-medium mb-2">Search Results</h3>
          <pre className="text-sm">
            {searchResults == null ? "" : sortedStringify(searchResults, 2)}
          </pre>
        </div>
      </div>
    );
  };

  return (
    <Dialog>
      <DialogTrigger asChild>
        <Button variant="outline">
          <SearchIcon className="h-4 w-4 mr-2" />
          Search
        </Button>
      </DialogTrigger>
      <DialogContent className="h-screen max-w-7xl">
        <DialogHeader>
          <DialogTitle>Preview Search</DialogTitle>
        </DialogHeader>
        {renderContent()}
      </DialogContent>
    </Dialog>
  );
};

export default SearchDialog;
