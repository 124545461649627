"use client";

import { FileEdit, MailCheck, MailX } from "lucide-react";
import { Card, CardContent, CardHeader, CardTitle } from "@/components/ui/card";
import { Skeleton } from "@/components/ui/skeleton";
import { BarChart } from "../tremor/BarChart";
import { AxiosErrorBox } from "../Error";
import { useHistogram } from "./hooks";
import { aggregateHistogramData } from "./helper";
import { HandoffStatus } from "@/client";
import LinkWithQuery from "../LinkWithQuery";
import { useNavigate, useParams } from "react-router-dom";

interface StateConfig {
  label: string;
  icon: typeof MailCheck | typeof FileEdit | typeof MailX;
  color: string;
}

const HANDOFF_STATES: Record<HandoffStatus, StateConfig> = {
  SUBMITTED: {
    label: "Submitted",
    icon: MailCheck,
    color: "#4ADE80", // green-400
  },
  DRAFT: {
    label: "Drafted",
    icon: FileEdit,
    color: "#FACC15", // yellow-400
  },
  CANCELLED: {
    label: "Cancelled",
    icon: MailX,
    color: "#F87171", // red-400
  },
} as const;

const HANDOFF_KEYS = Object.keys(HANDOFF_STATES) as HandoffStatus[];

function StateIndicator({
  state,
  count,
}: {
  state: HandoffStatus;
  count: number;
}) {
  const { projectId } = useParams();
  const { icon: IconComponent, label, color } = HANDOFF_STATES[state];

  return (
    <LinkWithQuery to={`/${projectId}/messages/search?handoff=${state}`}>
      <div className="relative z-30 flex flex-1 flex-col justify-center gap-1 px-6 text-left border-l sm:px-8">
        <span className="flex items-center gap-2 text-xs text-muted-foreground">
          <IconComponent className="h-4 w-4" style={{ color }} />
          {label}
        </span>
        <span className="text-lg font-bold leading-none sm:text-3xl">
          {count}
        </span>
      </div>
    </LinkWithQuery>
  );
}

const formatXAxisDate = (date: string) =>
  new Date(date).toLocaleDateString("en-US", {
    month: "short",
    day: "numeric",
  });

const formatTooltipDate = (date: string) =>
  new Date(date).toLocaleDateString("en-US", {
    month: "short",
    day: "numeric",
    year: "numeric",
  });

export default function HandoffChart() {
  const { projectId } = useParams();
  const navigate = useNavigate();
  const { data: chartData, isPending, error } = useHistogram("Handoff", "1d");

  if (error) return <AxiosErrorBox error={error} />;

  const aggregate = aggregateHistogramData(chartData, HANDOFF_KEYS);

  const colorMap = new Map(
    HANDOFF_KEYS.map((key) => [key, HANDOFF_STATES[key].color])
  );

  return (
    <div className="w-full">
      <Card>
        <CardHeader className="flex flex-col items-stretch space-y-2 md:pace-y-0 border-b p-0 md:flex-row py-3">
          <div className="flex flex-1 flex-col justify-center gap-1 px-6">
            <CardTitle>Handoffs</CardTitle>
          </div>
          <div className="flex">
            {HANDOFF_KEYS.map((key) => (
              <StateIndicator
                key={key}
                state={key}
                count={Number.isNaN(aggregate[key]) ? 0 : aggregate[key]}
              />
            ))}
          </div>
        </CardHeader>
        <CardContent className="pt-4">
          {isPending ? (
            <Skeleton className="h-64 w-full" />
          ) : (
            <BarChart
              className="h-64 w-full"
              data={chartData}
              index="date"
              categories={HANDOFF_KEYS}
              type="stacked"
              colorMap={colorMap}
              showLegend={false}
              allowDecimals={false}
              xAxisFormatter={formatXAxisDate}
              categoryFormatter={(category) =>
                HANDOFF_STATES[category as HandoffStatus].label || category
              }
              toolTipLabelFormatter={formatTooltipDate}
              onValueChange={(value) => {
                if (!value) return;
                const date = new Date(value.date);
                const endOfDay = new Date(date);
                endOfDay.setHours(23, 59, 59, 999);
                navigate(
                  `/${projectId}/messages/search?handoff=${value.categoryClicked}&since=${date.toISOString()}&until=${endOfDay.toISOString()}`
                );
              }}
            />
          )}
        </CardContent>
      </Card>
    </div>
  );
}
