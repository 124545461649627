import { useEffect, useState } from "react";
import {
  ArrowLeftRight,
  ChevronLeft,
  ChevronRight,
  ChevronsUpDown,
  Settings,
} from "lucide-react";

import { Avatar, AvatarFallback, AvatarImage } from "@/components/ui/avatar";
import { Button } from "@/components/ui/button";
import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuGroup,
  DropdownMenuItem,
  DropdownMenuLabel,
  DropdownMenuSeparator,
  DropdownMenuTrigger,
} from "@/components/ui/dropdown-menu";
import {
  SidebarMenu,
  SidebarMenuButton,
  SidebarMenuItem,
  useSidebar,
} from "@/components/ui/sidebar";
import {
  OrganizationProfile,
  useOrganization,
  useOrganizationList,
} from "@clerk/clerk-react";
import { useNavigate } from "react-router-dom";
import { toast } from "sonner";

interface OrganizationSwitcherProps {
  organizationId: string;
  imageUrl: string;
  name: string;
  onSwitch: (orgId: string) => void;
}

const OrganizationSwitcherItem = ({
  organizationId,
  imageUrl,
  name,
  onSwitch,
}: OrganizationSwitcherProps) => (
  <DropdownMenuItem
    key={organizationId}
    className="p-0 font-normal"
    onClick={() => onSwitch(organizationId)}
  >
    <div className="flex w-full items-center gap-2 px-2 py-1.5">
      <Avatar className="h-8 w-8 rounded-lg">
        <AvatarImage src={imageUrl} alt={name} />
        <AvatarFallback className="rounded-lg">
          {name.substring(0, 2).toUpperCase()}
        </AvatarFallback>
      </Avatar>
      <div className="grid flex-1 text-left text-sm leading-tight">
        <span className="truncate font-semibold">{name}</span>
      </div>
      <ArrowLeftRight className="ml-2 h-4 w-4 text-muted-foreground" />
    </div>
  </DropdownMenuItem>
);

const PaginationControls = ({
  hasPreviousPage,
  hasNextPage,
  isFetching,
  onPrevious,
  onNext,
}: {
  hasPreviousPage?: boolean;
  hasNextPage?: boolean;
  isFetching?: boolean;
  onPrevious: () => void;
  onNext: () => void;
}) => (
  <>
    <DropdownMenuSeparator />
    <div className="flex justify-between p-1">
      <Button
        variant="ghost"
        size="sm"
        className="h-8 w-8 p-0"
        disabled={!hasPreviousPage || isFetching}
        onClick={onPrevious}
      >
        <ChevronLeft className="h-4 w-4" />
      </Button>
      <Button
        variant="ghost"
        size="sm"
        className="h-8 w-8 p-0"
        disabled={!hasNextPage || isFetching}
        onClick={onNext}
      >
        <ChevronRight className="h-4 w-4" />
      </Button>
    </div>
  </>
);

export function NavOrganization() {
  const { organization, isLoaded: isActiveLoaded } = useOrganization();
  const {
    setActive,
    userMemberships: {
      data: userMembershipsData,
      isLoading: isMembershipsLoading,
      count: userMembershipsCount,
      fetchNext,
      fetchPrevious,
      hasNextPage,
      hasPreviousPage,
      isFetching,
    },
  } = useOrganizationList({
    userMemberships: {
      memberships: {
        pageSize: 10,
        keepPreviousData: true,
      },
    },
  });

  const { isMobile } = useSidebar();
  const navigate = useNavigate();
  const [showOrgProfile, setShowOrgProfile] = useState(false);
  const [isSettingOrg, setIsSettingOrg] = useState(false);

  // Handle setting the default organization
  useEffect(() => {
    const setDefaultOrganization = async () => {
      if (
        !isSettingOrg && // Prevent multiple simultaneous attempts
        !organization && // No active organization
        isActiveLoaded && // Organization status is loaded
        !isMembershipsLoading && // Memberships are loaded
        setActive && // setActive function is available
        (userMembershipsData?.length || 0) > 0 // Has at least one membership
      ) {
        try {
          setIsSettingOrg(true);
          await setActive({
            organization: userMembershipsData![0].organization.id,
          });
        } catch (error) {
          console.error("Failed to set default organization:", error);
        } finally {
          setIsSettingOrg(false);
        }
      }
    };

    void setDefaultOrganization();
  }, [
    organization,
    isActiveLoaded,
    isMembershipsLoading,
    setActive,
    userMembershipsData,
    isSettingOrg,
  ]);

  const handleOrganizationSwitch = async (orgId: string) => {
    try {
      await setActive!({ organization: orgId });
      window.location.href = "/"; // Reload the page
    } catch (err) {
      console.error("Failed to switch organization:", err);
    }
  };

  // Don't render until we have an organization
  if (!organization) {
    if (userMembershipsCount !== undefined && userMembershipsCount === 0) {
      console.error("No Organization found");
      toast.error("No Organization found - please contact support");
      return <>No Organization Found. Please contact support.</>;
    }
    return null;
  }
  return (
    <>
      {showOrgProfile && (
        <div className="fixed inset-0 z-50">
          <div
            className="absolute inset-0 bg-black/50"
            onClick={() => setShowOrgProfile(false)}
          />
          <div className="absolute left-1/2 top-1/2 w-full max-w-4xl -translate-x-1/2 -translate-y-1/2">
            <OrganizationProfile />
          </div>
        </div>
      )}

      <SidebarMenu>
        <SidebarMenuItem>
          <DropdownMenu>
            <DropdownMenuTrigger asChild>
              <SidebarMenuButton
                size="lg"
                className="data-[state=open]:bg-sidebar-accent data-[state=open]:text-sidebar-accent-foreground"
              >
                <Avatar className="h-8 w-8 rounded-lg">
                  <AvatarImage
                    src={organization.imageUrl}
                    alt={organization.name}
                  />
                  <AvatarFallback className="rounded-lg">
                    {organization.name.substring(0, 2).toUpperCase()}
                  </AvatarFallback>
                </Avatar>
                <div className="grid flex-1 text-left text-sm leading-tight">
                  <span className="truncate font-semibold">
                    {organization.name}
                  </span>
                  <span className="text-xs text-muted-foreground">
                    Organization
                  </span>
                </div>
                <ChevronsUpDown className="ml-auto size-4" />
              </SidebarMenuButton>
            </DropdownMenuTrigger>
            <DropdownMenuContent
              className="w-[--radix-dropdown-menu-trigger-width] min-w-56 rounded-lg"
              side={isMobile ? "bottom" : "right"}
              align="end"
              sideOffset={4}
            >
              <div className="max-h-[400px] overflow-y-auto">
                {userMembershipsData
                  ?.filter((mem) => mem.organization.id !== organization.id)
                  .map((mem) => (
                    <OrganizationSwitcherItem
                      key={mem.organization.id}
                      organizationId={mem.organization.id}
                      imageUrl={mem.organization.imageUrl}
                      name={mem.organization.name}
                      onSwitch={() =>
                        void handleOrganizationSwitch(mem.organization.id)
                      }
                    />
                  ))}
              </div>

              {(hasPreviousPage || hasNextPage) && (
                <PaginationControls
                  hasPreviousPage={hasPreviousPage}
                  hasNextPage={hasNextPage}
                  isFetching={isFetching}
                  onPrevious={() => fetchPrevious?.()}
                  onNext={() => fetchNext?.()}
                />
              )}

              <DropdownMenuSeparator />
              <DropdownMenuLabel
                className="p-0 font-normal cursor-pointer"
                onClick={() => navigate("/")}
              >
                <div className="flex items-center gap-2 px-2 py-1.5">
                  <Avatar className="h-8 w-8 rounded-lg">
                    <AvatarImage
                      src={organization.imageUrl}
                      alt={organization.name}
                    />
                    <AvatarFallback className="rounded-lg">
                      {organization.name.substring(0, 2).toUpperCase()}
                    </AvatarFallback>
                  </Avatar>
                  <div className="grid flex-1 text-left text-sm leading-tight">
                    <span className="truncate font-semibold">
                      {organization.name}
                    </span>
                  </div>
                </div>
              </DropdownMenuLabel>

              <DropdownMenuSeparator />
              <DropdownMenuGroup>
                <DropdownMenuItem onClick={() => setShowOrgProfile(true)}>
                  <Settings className="mr-2 h-4 w-4" />
                  Manage Organization
                </DropdownMenuItem>
              </DropdownMenuGroup>
            </DropdownMenuContent>
          </DropdownMenu>
        </SidebarMenuItem>
      </SidebarMenu>
    </>
  );
}
export default NavOrganization;
