import { listTopicsOptions } from "@/client/@tanstack/react-query.gen";
import { createTopicColorMap } from "@/colors";
import { useQuery } from "@tanstack/react-query";
import { useParams } from "react-router-dom";
import { Card, CardContent, CardHeader, CardTitle } from "../ui/card";
import { BarChart } from "../tremor/BarChart";
import { useHistogram } from "../charts/hooks";
import { histogramToAggregatedData } from "../charts/helper";
import { useState } from "react";
import { Switch } from "../ui/switch";
import { Label } from "../ui/label";
import { AxiosErrorBox } from "../Error";
import { Skeleton } from "../ui/skeleton";
import { DonutChart } from "../tremor/DonutChart";

export default function TopicChart({
  groupingInterval,
}: {
  groupingInterval: string;
}) {
  const { projectId } = useParams();
  const {
    data: chartData,
    keys: topicNames,
    isPending,
    error,
  } = useHistogram("ConversationTopicV2", groupingInterval);
  const [chartType, setChartType] = useState<"percent" | "stacked">("stacked");

  const { data } = useQuery({
    ...listTopicsOptions({
      path: {
        project_id: Number(projectId),
      },
    }),
  });
  const topics = data?.data || [];
  const getTopicColor = createTopicColorMap(topics.map((topic) => topic.id));

  if (error) return <AxiosErrorBox error={error} />;

  const donutData = histogramToAggregatedData(chartData, topicNames);

  const colorMap = new Map(
    topics.map((topic) => [topic.title, getTopicColor(topic.id).main])
  );

  return (
    <Card className="lg:col-span-3">
      <CardHeader>
        <div className="flex justify-between items-center">
          <CardTitle>Topic Trends</CardTitle>
          <div className="flex items-center gap-2">
            <Switch
              checked={chartType === "percent"}
              onCheckedChange={(checked) =>
                setChartType(checked ? "percent" : "stacked")
              }
            />
            <Label htmlFor="relative-switch">Show relative values</Label>
          </div>
        </div>
      </CardHeader>
      <CardContent>
        {isPending ? (
          <Skeleton className="h-72" />
        ) : (
          <div className="flex items-center">
            <div>
              <DonutChart
                variant="pie"
                className="w-72"
                data={donutData}
                category="name"
                value="percent"
                colorMap={colorMap}
                valueFormatter={(number: number) => {
                  return `${number.toFixed(2)}%`;
                }}
              />
              <h2 className="text-center mt-2">Topic Distribution</h2>
            </div>
            <BarChart
              className="h-72"
              data={chartData}
              index="date"
              categories={topics.map((topic) => topic.title)}
              colorMap={colorMap}
              type={chartType}
              showLegend={false}
              xAxisFormatter={(date: string) =>
                new Date(date).toLocaleDateString("en-US", {
                  month: "short",
                  day: "numeric",
                })
              }
              toolTipLabelFormatter={(date: string) => {
                const startDate = new Date(date);
                const endDate = new Date(startDate);
                endDate.setDate(endDate.getDate() + 6);
                return `${startDate.toLocaleDateString("en-US", {
                  month: "short",
                  day: "numeric",
                })} - ${endDate.toLocaleDateString("en-US", {
                  month: "short",
                  day: "numeric",
                  year: "numeric",
                })}`;
              }}
            />
          </div>
        )}
      </CardContent>
    </Card>
  );
}
