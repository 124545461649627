import { WidgetProps, RegistryWidgetsType } from "@rjsf/utils";
import { Checkbox } from "@/components/ui/checkbox";
import { Input } from "@/components/ui/input";
import { Switch } from "@/components/ui/switch";
import { Textarea } from "@/components/ui/textarea";
import { RadioGroup, RadioGroupItem } from "@/components/ui/radio-group";
import { Label } from "@/components/ui/label";
import CheckboxSelectWidget from "./CustomSelectWidget";

const widgets: RegistryWidgetsType = {
  // Existing widgets
  SelectWidget: CheckboxSelectWidget, // Keep your existing custom select widget

  // Text input widget
  TextWidget: function TextWidget(props: WidgetProps) {
    return (
      <Input
        type={props.type}
        className="w-full"
        disabled={props.disabled || props.readonly}
        id={props.id}
        name={props.label || undefined}
        placeholder={props.placeholder}
        onChange={(e) => props.onChange(e.target.value)}
        value={props.value || ""}
      />
    );
  },

  // Textarea widget
  TextareaWidget: function TextareaWidget(props: WidgetProps) {
    return (
      <Textarea
        className="w-full"
        disabled={props.disabled || props.readonly}
        id={props.id}
        name={props.label || undefined}
        placeholder={props.placeholder}
        onChange={(e) => props.onChange(e.target.value)}
        value={props.value || ""}
      />
    );
  },

  // Checkbox widget
  CheckboxWidget: function CheckboxWidget(props: WidgetProps) {
    return (
      <div className="flex items-center space-x-2">
        <Checkbox
          id={props.id}
          checked={props.value}
          disabled={props.disabled}
          onCheckedChange={(checked) => props.onChange(checked)}
        />
        <Label htmlFor={props.id}>{props.label}</Label>
      </div>
    );
  },

  // Radio widget
  RadioWidget: function RadioWidget(props: WidgetProps) {
    const { options } = props;
    const enumOptions = options.enumOptions || [];

    return (
      <RadioGroup
        disabled={props.disabled}
        value={props.value}
        onValueChange={(value) => props.onChange(value)}
      >
        {enumOptions.map((option: any) => (
          <div key={option.value} className="flex items-center space-x-2">
            <RadioGroupItem
              value={option.value}
              id={`${props.id}-${option.value}`}
            />
            <Label htmlFor={`${props.id}-${option.value}`}>
              {option.label}
            </Label>
          </div>
        ))}
      </RadioGroup>
    );
  },

  // Switch widget (alternative to checkbox)
  SwitchWidget: function SwitchWidget(props: WidgetProps) {
    return (
      <div className="flex items-center space-x-2">
        <Switch
          id={props.id}
          checked={props.value}
          disabled={props.disabled}
          onCheckedChange={(checked) => props.onChange(checked)}
        />
        <Label htmlFor={props.id}>{props.label}</Label>
      </div>
    );
  },
};

export default widgets;
