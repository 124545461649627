import React from "react";
import {
  Bar,
  CartesianGrid,
  Label,
  BarChart as RechartsBarChart,
  Legend as RechartsLegend,
  ResponsiveContainer,
  Tooltip,
  XAxis,
  YAxis,
} from "recharts";
import { AxisDomain } from "recharts/types/util/types";
import { getYAxisDomain } from "./chartUtils";
import { useOnWindowResize } from "./useOnWindowResize";

//#region Shape
function deepEqual<T>(obj1: T, obj2: T): boolean {
  if (obj1 === obj2) return true;

  if (
    typeof obj1 !== "object" ||
    typeof obj2 !== "object" ||
    obj1 === null ||
    obj2 === null
  ) {
    return false;
  }

  const keys1 = Object.keys(obj1) as Array<keyof T>;
  const keys2 = Object.keys(obj2) as Array<keyof T>;

  if (keys1.length !== keys2.length) return false;

  for (const key of keys1) {
    if (!keys2.includes(key) || !deepEqual(obj1[key], obj2[key])) return false;
  }

  return true;
}

const renderShape = (
  props: any,
  activeBar: any | undefined,
  activeLegend: string | undefined,
  layout: string,
  colorMap: Map<string, string>
) => {
  const { fillOpacity, name, payload, value } = props;
  let { x, width, y, height } = props;

  if (layout === "horizontal" && height < 0) {
    y += height;
    height = Math.abs(height);
  } else if (layout === "vertical" && width < 0) {
    x += width;
    width = Math.abs(width);
  }

  const opacity =
    activeBar || (activeLegend && activeLegend !== name)
      ? deepEqual(activeBar, { ...payload, value })
        ? fillOpacity
        : 0.3
      : fillOpacity;

  const color = colorMap.get(name) || "#666666";

  return (
    <rect
      x={x}
      y={y}
      width={width}
      height={height}
      opacity={opacity}
      style={{ fill: color }}
    />
  );
};

//#region Legend
interface LegendItemProps {
  name: string;
  color: string;
  onClick?: (name: string) => void;
  activeLegend?: string;
}

const LegendItem = ({
  name,
  color,
  onClick,
  activeLegend,
}: LegendItemProps) => {
  const hasOnValueChange = !!onClick;

  return (
    <li
      className={`
        group inline-flex flex-nowrap items-center gap-1.5 
        whitespace-nowrap rounded px-2 py-1 transition
        ${
          hasOnValueChange
            ? "cursor-pointer hover:bg-gray-100 dark:hover:bg-gray-800"
            : "cursor-default"
        }
      `}
      onClick={(e) => {
        e.stopPropagation();
        onClick?.(name);
      }}
    >
      <span
        style={{
          backgroundColor: color,
          opacity: activeLegend && activeLegend !== name ? 0.4 : 1,
        }}
        className="size-2 shrink-0 rounded-sm"
        aria-hidden={true}
      />
      <p
        className={`
          truncate whitespace-nowrap text-xs
          text-gray-700 dark:text-gray-300
          ${hasOnValueChange ? "group-hover:text-gray-900 dark:group-hover:text-gray-50" : ""}
          ${activeLegend && activeLegend !== name ? "opacity-40" : "opacity-100"}
        `}
      >
        {name}
      </p>
    </li>
  );
};

interface LegendProps extends React.OlHTMLAttributes<HTMLOListElement> {
  categories: string[];
  colorMap: Map<string, string>;
  onClickLegendItem?: (category: string) => void;
  activeLegend?: string;
}

const Legend = React.forwardRef<HTMLOListElement, LegendProps>((props, ref) => {
  const {
    categories,
    colorMap,
    className,
    onClickLegendItem,
    activeLegend,
    ...other
  } = props;

  return (
    <ol
      ref={ref}
      className={`relative overflow-hidden flex flex-wrap ${className || ""}`}
      {...other}
    >
      {categories.map((category, index) => (
        <LegendItem
          key={`item-${index}`}
          name={category}
          color={colorMap.get(category) || "#666666"}
          onClick={onClickLegendItem}
          activeLegend={activeLegend}
        />
      ))}
    </ol>
  );
});

Legend.displayName = "Legend";

const ChartLegend = (
  { payload }: any,
  colorMap: Map<string, string>,
  setLegendHeight: React.Dispatch<React.SetStateAction<number>>,
  activeLegend: string | undefined,
  onClick?: (category: string) => void,
  legendPosition: "left" | "center" | "right" = "right",
  yAxisWidth?: number
) => {
  const legendRef = React.useRef<HTMLDivElement>(null);

  useOnWindowResize(() => {
    const calculateHeight = (height: number | undefined) =>
      height ? Number(height) + 15 : 60;
    setLegendHeight(calculateHeight(legendRef.current?.clientHeight));
  });

  const filteredPayload = payload.filter((item: any) => item.type !== "none");
  const paddingLeft =
    legendPosition === "left" && yAxisWidth ? yAxisWidth - 8 : 0;

  return (
    <div
      style={{ paddingLeft }}
      ref={legendRef}
      className={`
        flex items-center
        ${legendPosition === "center" ? "justify-center" : ""}
        ${legendPosition === "left" ? "justify-start" : ""}
        ${legendPosition === "right" ? "justify-end" : ""}
      `}
    >
      <Legend
        categories={filteredPayload.map((entry: any) => entry.value)}
        colorMap={colorMap}
        onClickLegendItem={onClick}
        activeLegend={activeLegend}
      />
    </div>
  );
};

//#region Tooltip
interface TooltipProps {
  active: boolean | undefined;
  payload: Array<{
    value: number;
    category: string;
    color: string;
  }>;
  label: string;
  valueFormatter: (value: number) => string;
  labelFormatter: (value: string) => string;
  categoryFormatter: (category: string) => string;
}

const ChartTooltip = ({
  active,
  payload,
  label,
  valueFormatter,
  labelFormatter,
  categoryFormatter,
}: TooltipProps) => {
  if (active && payload && payload.length) {
    return (
      <div className="rounded-md border border-gray-200 dark:border-gray-800 bg-white dark:bg-gray-950 text-sm shadow-md">
        <div className="border-b border-inherit px-4 py-2">
          <p className="font-medium text-gray-900 dark:text-gray-50">
            {labelFormatter(label)}
          </p>
        </div>
        <div className="space-y-1 px-4 py-2">
          {payload.map(({ value, category, color }, index) => (
            <div
              key={`id-${index}`}
              className="flex items-center justify-between space-x-8"
            >
              <div className="flex items-center space-x-2">
                <span
                  style={{ backgroundColor: color }}
                  className="size-2 shrink-0 rounded-sm"
                  aria-hidden={true}
                />
                <p className="whitespace-nowrap text-right text-gray-700 dark:text-gray-300">
                  {categoryFormatter(category)}
                </p>
              </div>
              <p className="whitespace-nowrap text-right font-medium tabular-nums text-gray-900 dark:text-gray-50">
                {valueFormatter(value)}
              </p>
            </div>
          ))}
        </div>
      </div>
    );
  }
  return null;
};

//#region BarChart
type BaseEventProps = {
  eventType: "category" | "bar";
  categoryClicked: string;
  [key: string]: number | string;
};

type BarChartEventProps = BaseEventProps | null | undefined;

interface BarChartProps extends React.HTMLAttributes<HTMLDivElement> {
  data: Record<string, any>[];
  index: string;
  categories: string[];
  colorMap: Map<string, string>;
  valueFormatter?: (value: number) => string;
  startEndOnly?: boolean;
  showXAxis?: boolean;
  showYAxis?: boolean;
  showGridLines?: boolean;
  yAxisWidth?: number;
  intervalType?: "preserveStartEnd" | "equidistantPreserveStart";
  showTooltip?: boolean;
  showLegend?: boolean;
  autoMinValue?: boolean;
  minValue?: number;
  maxValue?: number;
  allowDecimals?: boolean;
  onValueChange?: (value: BarChartEventProps) => void;
  tickGap?: number;
  barCategoryGap?: string | number;
  xAxisLabel?: string;
  yAxisLabel?: string;
  xAxisFormatter?: (value: string) => string;
  categoryFormatter?: (category: string) => string;
  toolTipLabelFormatter?: (value: string) => string;
  layout?: "vertical" | "horizontal";
  type?: "default" | "stacked" | "percent";
  legendPosition?: "left" | "center" | "right";
}

const BarChart = React.forwardRef<HTMLDivElement, BarChartProps>(
  (props, forwardedRef) => {
    const {
      data = [],
      categories = [],
      index,
      colorMap,
      valueFormatter = (value: number) => value.toString(),
      startEndOnly = false,
      showXAxis = true,
      showYAxis = true,
      showGridLines = true,
      yAxisWidth = 56,
      intervalType = "equidistantPreserveStart",
      showTooltip = true,
      showLegend = true,
      autoMinValue = false,
      minValue,
      maxValue,
      allowDecimals = true,
      className,
      onValueChange,
      barCategoryGap,
      tickGap = 5,
      xAxisLabel,
      yAxisLabel,
      xAxisFormatter,
      categoryFormatter = (category: string) => category,
      toolTipLabelFormatter = (value: string) => value,
      layout = "horizontal",
      type = "default",
      legendPosition = "right",
      ...other
    } = props;

    const [legendHeight, setLegendHeight] = React.useState(60);
    const [activeLegend, setActiveLegend] = React.useState<string | undefined>(
      undefined
    );
    const [activeBar, setActiveBar] = React.useState<any | undefined>(
      undefined
    );
    const yAxisDomain = getYAxisDomain(autoMinValue, minValue, maxValue);
    const hasOnValueChange = !!onValueChange;
    const stacked = type === "stacked" || type === "percent";
    const paddingValue =
      (!showXAxis && !showYAxis) || (startEndOnly && !showYAxis) ? 0 : 20;

    function valueToPercent(value: number) {
      return `${(value * 100).toFixed(0)}%`;
    }

    function onBarClick(data: any, _: any, event: React.MouseEvent) {
      event.stopPropagation();
      if (!onValueChange) return;
      if (deepEqual(activeBar, { ...data.payload, value: data.value })) {
        setActiveLegend(undefined);
        setActiveBar(undefined);
        onValueChange?.(null);
      } else {
        setActiveLegend(data.tooltipPayload?.[0]?.dataKey);
        setActiveBar({
          ...data.payload,
          value: data.value,
        });
        onValueChange?.({
          eventType: "bar",
          categoryClicked: data.tooltipPayload?.[0]?.dataKey,
          ...data.payload,
        });
      }
    }

    function onCategoryClick(dataKey: string) {
      if (!hasOnValueChange) return;
      if (dataKey === activeLegend && !activeBar) {
        setActiveLegend(undefined);
        onValueChange?.(null);
      } else {
        setActiveLegend(dataKey);
        onValueChange?.({
          eventType: "category",
          categoryClicked: dataKey,
        });
      }
      setActiveBar(undefined);
    }

    return (
      <div
        ref={forwardedRef}
        className={`h-80 w-full ${className || ""}`}
        {...other}
      >
        <ResponsiveContainer>
          <RechartsBarChart
            data={data}
            onClick={
              hasOnValueChange && (activeLegend || activeBar)
                ? () => {
                    setActiveBar(undefined);
                    setActiveLegend(undefined);
                    onValueChange?.(null);
                  }
                : undefined
            }
            margin={{
              bottom: xAxisLabel ? 30 : undefined,
              left: yAxisLabel ? 20 : undefined,
              right: yAxisLabel ? 5 : undefined,
              top: 5,
            }}
            stackOffset={type === "percent" ? "expand" : undefined}
            layout={layout}
            barCategoryGap={barCategoryGap}
          >
            {showGridLines && (
              <CartesianGrid
                className="stroke-gray-200 stroke-1 dark:stroke-gray-800"
                horizontal={layout !== "vertical"}
                vertical={layout === "vertical"}
              />
            )}
            <XAxis
              hide={!showXAxis}
              tick={{
                transform:
                  layout !== "vertical" ? "translate(0, 6)" : undefined,
              }}
              fill=""
              stroke=""
              className="text-xs fill-gray-500 dark:fill-gray-500"
              tickLine={false}
              axisLine={false}
              minTickGap={tickGap}
              {...(layout !== "vertical"
                ? {
                    padding: {
                      left: paddingValue,
                      right: paddingValue,
                    },
                    dataKey: index,
                    interval: startEndOnly ? "preserveStartEnd" : intervalType,
                    ticks: startEndOnly
                      ? [data[0][index], data[data.length - 1][index]]
                      : undefined,
                  }
                : {
                    type: "number",
                    domain: yAxisDomain as AxisDomain,
                    tickFormatter:
                      type === "percent" ? valueToPercent : valueFormatter,
                    allowDecimals,
                  })}
              tickFormatter={xAxisFormatter}
            >
              {xAxisLabel && (
                <Label
                  position="insideBottom"
                  offset={-20}
                  className="fill-gray-800 text-sm font-medium dark:fill-gray-200"
                >
                  {xAxisLabel}
                </Label>
              )}
            </XAxis>
            <YAxis
              width={yAxisWidth}
              hide={!showYAxis}
              axisLine={false}
              tickLine={false}
              fill=""
              stroke=""
              className="text-xs fill-gray-500 dark:fill-gray-500"
              tick={{
                transform:
                  layout !== "vertical"
                    ? "translate(-3, 0)"
                    : "translate(0, 0)",
              }}
              {...(layout !== "vertical"
                ? {
                    type: "number",
                    domain: yAxisDomain as AxisDomain,
                    tickFormatter:
                      type === "percent" ? valueToPercent : valueFormatter,
                    allowDecimals,
                  }
                : {
                    dataKey: index,
                    ticks: startEndOnly
                      ? [data[0][index], data[data.length - 1][index]]
                      : undefined,
                    type: "category",
                    interval: "equidistantPreserveStart",
                  })}
            >
              {yAxisLabel && (
                <Label
                  position="insideLeft"
                  style={{ textAnchor: "middle" }}
                  angle={-90}
                  offset={-15}
                  className="fill-gray-800 text-sm font-medium dark:fill-gray-200"
                >
                  {yAxisLabel}
                </Label>
              )}
            </YAxis>
            <Tooltip
              wrapperStyle={{ outline: "none" }}
              isAnimationActive={true}
              animationDuration={100}
              cursor={{ fill: "#d1d5db", opacity: "0.15" }}
              offset={20}
              position={{
                y: layout === "horizontal" ? 0 : undefined,
                x: layout === "horizontal" ? undefined : yAxisWidth + 20,
              }}
              content={({ active, payload, label }) => {
                const cleanPayload = payload
                  ? payload.map((item: any) => ({
                      category: item.dataKey,
                      value: item.value,
                      color: colorMap.get(item.dataKey) || "#666666",
                    }))
                  : [];

                return showTooltip && active ? (
                  <ChartTooltip
                    active={active}
                    payload={cleanPayload}
                    label={label}
                    valueFormatter={valueFormatter}
                    categoryFormatter={categoryFormatter}
                    labelFormatter={toolTipLabelFormatter}
                  />
                ) : null;
              }}
            />
            {showLegend && (
              <RechartsLegend
                verticalAlign="top"
                height={legendHeight}
                content={({ payload }) =>
                  ChartLegend(
                    { payload },
                    colorMap,
                    setLegendHeight,
                    activeLegend,
                    hasOnValueChange
                      ? (clickedLegendItem: string) =>
                          onCategoryClick(clickedLegendItem)
                      : undefined,
                    legendPosition,
                    yAxisWidth
                  )
                }
              />
            )}
            {categories.map((category) => (
              <Bar
                key={category}
                name={category}
                type="linear"
                dataKey={category}
                stackId={stacked ? "stack" : undefined}
                isAnimationActive={false}
                className={onValueChange ? "cursor-pointer" : ""}
                fill="transparent" // We'll set the fill color in the shape renderer
                shape={(props: any) =>
                  renderShape(props, activeBar, activeLegend, layout, colorMap)
                }
                onClick={onBarClick}
              />
            ))}
          </RechartsBarChart>
        </ResponsiveContainer>
      </div>
    );
  }
);

BarChart.displayName = "BarChart";

export { BarChart, type BarChartEventProps };
