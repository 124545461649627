import { useState } from "react";
import {
  Dialog,
  DialogContent,
  DialogHeader,
  DialogTitle,
  DialogTrigger,
  DialogFooter,
} from "@/components/ui/dialog";
import { Button } from "@/components/ui/button";
import { Textarea } from "@/components/ui/textarea";
import { Label } from "@/components/ui/label";
import { Input } from "@/components/ui/input";

const HtmlTriggerDialog = ({
  onSave,
  disabled,
}: {
  onSave: (trigger: any) => void;
  disabled?: boolean;
}) => {
  const [isOpen, setIsOpen] = useState(false);
  const [htmlContent, setHtmlContent] = useState("");
  const [triggerName, setTriggerName] = useState("");
  const [description, setDescription] = useState("");

  const handleSave = () => {
    const trigger = {
      name: triggerName,
      description: description,
      actions: [
        {
          type: "html",
          html: htmlContent,
        },
      ],
    };
    onSave(trigger);
    setIsOpen(false);
    resetForm();
  };

  const resetForm = () => {
    setHtmlContent("");
    setTriggerName("");
    setDescription("");
  };

  return (
    <Dialog open={isOpen} onOpenChange={setIsOpen}>
      <DialogTrigger asChild>
        <Button type="button" variant="outline" disabled={disabled}>
          Add HTML Trigger
        </Button>
      </DialogTrigger>
      <DialogContent className="max-w-5xl">
        <DialogHeader>
          <DialogTitle>Add HTML Trigger</DialogTitle>
        </DialogHeader>
        <div className="grid gap-4 py-4">
          <div className="grid grid-cols-4 items-center gap-4">
            <Label htmlFor="triggerName" className="text-right">
              Trigger Name
            </Label>
            <Input
              id="triggerName"
              value={triggerName}
              onChange={(e) => setTriggerName(e.target.value)}
              className="col-span-3"
              placeholder="Enter trigger name..."
            />
          </div>
          <div className="grid grid-cols-4 items-center gap-4">
            <Label htmlFor="description" className="text-right">
              Description
            </Label>
            <Input
              id="description"
              value={description}
              onChange={(e) => setDescription(e.target.value)}
              className="col-span-3"
              placeholder="Enter description..."
            />
          </div>
          <div className="grid grid-cols-2 gap-4">
            <div className="space-y-2">
              <Label htmlFor="html-content">HTML Content</Label>
              <Textarea
                id="html-content"
                value={htmlContent}
                onChange={(e) => setHtmlContent(e.target.value)}
                className="h-96 font-mono"
                placeholder="Enter HTML content..."
              />
            </div>
            <div className="space-y-2">
              <Label>Preview</Label>
              <div className="h-96 border rounded-md p-4 overflow-auto bg-white">
                <div dangerouslySetInnerHTML={{ __html: htmlContent }} />
              </div>
            </div>
          </div>
        </div>
        <DialogFooter>
          <Button
            type="button"
            variant="outline"
            onClick={() => setIsOpen(false)}
          >
            Cancel
          </Button>
          <Button
            type="button"
            onClick={handleSave}
            disabled={!triggerName || !description || !htmlContent}
          >
            Save Trigger
          </Button>
        </DialogFooter>
      </DialogContent>
    </Dialog>
  );
};

export default HtmlTriggerDialog;
