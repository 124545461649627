import { useCurrentProjectBreadcrumbs } from "@/hooks";
import NavigationHeader from "../sidebar/NavigationHeader";
import PageHeader from "../PageHeader";
import { lazy, Suspense } from "react";
import { useNavigate, useParams } from "react-router-dom";
import TopicsClusterSkeleton from "./TopicsClusterSkeleton";
import { ArrowLeftIcon, ArrowUpRightIcon } from "lucide-react";
import { Button } from "../ui/button";

export function TopicsVisualizationButton() {
  const navigate = useNavigate();
  return (
    <div>
      <Button
        variant="outline"
        onClick={() => navigate("./../topics-visualization")}
        className="bg-gradient-to-r from-blue-500/10 to-indigo-500/10 hover:from-blue-500/20 hover:to-indigo-500/20 border-blue-200 hover:border-indigo-300 transition-all duration-300 shadow-md hover:shadow-blue-200/50 group"
      >
        <span className="bg-gradient-to-r from-blue-600 to-indigo-600 bg-clip-text text-transparent font-medium group-hover:from-blue-500 group-hover:to-indigo-500 transition-all duration-300">
          ✨ Open 3D Visualization
        </span>
        <ArrowUpRightIcon className="h-4 w-4 ml-2 text-blue-500 group-hover:text-indigo-500 transition-colors duration-300" />
      </Button>
    </div>
  );
}

// https://stackoverflow.com/questions/53252861/react-preloading-components-with-lazy-suspense
const componentPromise = import("./TopicsCluster");
const TopicCluster = lazy(() => componentPromise);

export default function TopicsPage() {
  const { projectId } = useParams();
  const navigate = useNavigate();
  const breadcrumbs = [
    ...useCurrentProjectBreadcrumbs(),
    { label: "Topics", href: `/${projectId}/topics` },
    { label: "Visualization" },
  ];

  return (
    <>
      <NavigationHeader items={breadcrumbs} />
      <PageHeader title="Topics Visualization">
        <Button variant="outline" onClick={() => navigate("./../topics")}>
          <ArrowLeftIcon className="h-4 w-4" />
          Back to Topics
        </Button>
      </PageHeader>
      <div
        className="w-full"
        // HACK(memben): Make the scatter plot fill the screen, 1rem for SidebarInset, 16rem for NavHeader, 20rem __approximation__ for the PageHeader 4 * 37 ~= 150px
        style={{ height: `calc(100vh - 150px)` }}
      >
        <Suspense fallback={<TopicsClusterSkeleton />}>
          <TopicCluster projectId={Number(projectId)} />
        </Suspense>
      </div>
    </>
  );
}
