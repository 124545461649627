import { LucideIcon } from "lucide-react";
import { Checkbox } from "@/components/ui/checkbox";

interface Option<T> {
  icon?: LucideIcon;
  label: string;
  value: T;
}

interface MultiSelectFilterProps<T> {
  options: Option<T>[];
  selected: T[];
  onSelect: (value: T) => void;
  onUnselect: (value: T) => void;
}

const MultiSelectFilter = <T,>({
  options,
  selected,
  onSelect,
  onUnselect,
}: MultiSelectFilterProps<T>) => {
  const handleClick = (value: T) => {
    if (selected.includes(value)) {
      onUnselect(value);
    } else {
      onSelect(value);
    }
  };

  return (
    <>
      {options.map((option) => (
        <div
          key={option.label}
          className="flex items-center px-3 py-2 hover:bg-gray-100 cursor-pointer"
          onClick={() => handleClick(option.value)}
        >
          <Checkbox
            checked={selected.includes(option.value)}
            className="mr-2"
          />
          {option.icon && <option.icon className="mr-2 h-4 w-4" />}
          <span className="text-sm">{option.label}</span>
        </div>
      ))}
    </>
  );
};

export default MultiSelectFilter;
