import { useMutation, useQueryClient } from "@tanstack/react-query";
import { RefreshCw, Upload } from "lucide-react";
import { useState } from "react";
import { toast } from "sonner";

import {
  listDataproviderSnapshotsOptions,
  snapshotDataproviderMutation,
} from "@/client/@tanstack/react-query.gen";
import { DataProviderRead } from "@/client/types.gen";
import { Button } from "@/components/ui/button";
import {
  Tooltip,
  TooltipContent,
  TooltipProvider,
  TooltipTrigger,
} from "@/components/ui/tooltip";
import { cn } from "@/lib/utils";

import { UploadDialog } from "../sources/SourceUploadDialog";
import useFlagEnabled from "@/features";

export const DataProviderSyncButton = ({
  provider,
  isSyncing,
}: {
  provider: DataProviderRead;
  isSyncing: boolean;
}) => {
  const queryClient = useQueryClient();
  const flagSync = useFlagEnabled("sync-dataprovider");

  const createSnapshot = useMutation({
    ...snapshotDataproviderMutation(),
    onSuccess: () => {
      toast("Success", {
        description: "Sync started successfully",
      });
      void queryClient.invalidateQueries({
        queryKey: listDataproviderSnapshotsOptions({
          path: {
            project_id: provider.project_id,
            data_provider_id: provider.id,
          },
        }).queryKey,
      });
    },
  });

  return (
    <TooltipProvider>
      <Tooltip delayDuration={100}>
        <TooltipTrigger asChild>
          <Button
            variant="ghost"
            size="icon"
            onClick={() => {
              createSnapshot.mutate({
                path: {
                  project_id: provider.project_id,
                  data_provider_id: provider.id,
                },
                body: {
                  project_id: provider.project_id,
                  data_provider_id: provider.id,
                  copy_data: false,
                },
              });
            }}
            disabled={createSnapshot.isPending || isSyncing || !flagSync}
          >
            <RefreshCw
              className={`h-4 w-4 text-gray-500 ${
                createSnapshot.isPending || isSyncing ? "animate-spin-slow" : ""
              }`}
            />
          </Button>
        </TooltipTrigger>
        <TooltipContent>
          {isSyncing ? "Sync in progress" : "Start sync"}
        </TooltipContent>
      </Tooltip>
    </TooltipProvider>
  );
};

export const HumanUploadButton = ({
  provider,
  hasActiveSnapshot,
}: {
  provider: DataProviderRead;
  hasActiveSnapshot: boolean;
}) => {
  const [showUploadDialog, setShowUploadDialog] = useState(false);

  return (
    <>
      <TooltipProvider>
        <Tooltip delayDuration={100}>
          <TooltipTrigger asChild>
            <Button
              variant="ghost"
              size="icon"
              className={cn(
                hasActiveSnapshot && "text-amber-500 hover:text-amber-600",
                !hasActiveSnapshot && "text-gray-500 hover:text-gray-600"
              )}
              onClick={() => setShowUploadDialog(true)}
            >
              <Upload className="h-4 w-4" />
            </Button>
          </TooltipTrigger>
          <TooltipContent>
            {hasActiveSnapshot
              ? "Upload in Progress - Click to Add More"
              : "Upload Sources"}
          </TooltipContent>
        </Tooltip>
      </TooltipProvider>
      <UploadDialog
        provider={provider}
        open={showUploadDialog}
        onOpenChange={setShowUploadDialog}
      />
    </>
  );
};
