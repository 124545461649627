import { LucideIcon } from "lucide-react";
import { useLocation, useParams } from "react-router-dom";
import { SidebarMenuItem, SidebarMenuButton } from "../ui/sidebar";
import { useSidebar } from "../ui/sidebar";
import LinkWithQuery from "../LinkWithQuery";

function isTabActive(navigationTab: string) {
  const { projectId } = useParams();
  const { pathname } = useLocation();
  return (
    pathname === navigationTab ||
    (pathname.startsWith(`${navigationTab}`) &&
      navigationTab !== `/${projectId}`) // NOTE(memben) exclude always matching Dashboard with href !== `/${projectId}`
  );
}

export default function SidebarNavItem({
  name,
  href,
  Icon,
}: {
  name: string;
  href: string;
  Icon: LucideIcon;
}): JSX.Element {
  const { setOpenMobile } = useSidebar();
  return (
    <SidebarMenuItem>
      <SidebarMenuButton
        asChild
        isActive={isTabActive(href)}
        onClick={() => setOpenMobile(false)}
      >
        {/* NOTE(memben): allow seamingless navigation */}
        <LinkWithQuery to={href}>
          <Icon className="size-4" />
          <span>{name}</span>
        </LinkWithQuery>
      </SidebarMenuButton>
    </SidebarMenuItem>
  );
}
