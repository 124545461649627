import { Card, CardContent, CardHeader, CardTitle } from "../ui/card";
import { histogramToAggregatedData } from "./helper";
import { useHistogram } from "./hooks";
import { AxiosErrorBox } from "../Error";
import { Skeleton } from "../ui/skeleton";
import { BarList } from "../tremor/BarList";
import { useNavigate, useParams } from "react-router-dom";

export default function UsageByPageChart() {
  const { projectId } = useParams();
  const navigate = useNavigate();
  const MAX_ITEMS = 7;
  const {
    data: chartData,
    keys,
    isPending,
    error,
  } = useHistogram("ChatWebsiteUrl", "1d");
  const barListData = histogramToAggregatedData(chartData, keys);
  const barListDataTrimmed = [
    ...barListData.slice(0, MAX_ITEMS - 1),
    ...(barListData.length > MAX_ITEMS
      ? [
          {
            name: "Other",
            value: barListData
              .slice(MAX_ITEMS - 1)
              .reduce((acc, { value }) => acc + value, 0),
          },
        ]
      : []),
  ];

  if (error) return AxiosErrorBox({ error });

  return (
    <Card>
      <CardHeader>
        <CardTitle className="text-lg  font-semibold">User By Page</CardTitle>
      </CardHeader>
      <CardContent>
        {isPending ? (
          <Skeleton className="h-60" />
        ) : (
          <BarList
            data={barListDataTrimmed}
            labelFormatter={(label) => {
              if (label === "no_url") {
                return "No URL";
              }
              return label;
            }}
            onValueChange={(value) => {
              navigate(`/${projectId}/messages/search?pages=${value.name}`);
            }}
          />
        )}
      </CardContent>
    </Card>
  );
}
